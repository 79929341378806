import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { AuthenticationState } from "../../../model/state/authentication-state"
import { ApplicationState } from "../../../store"
import { HorizontalTimeline, TimelineElement } from "../../generic/horizontal-timeline"
import { FlowStep } from "../../../model/entities/flow-step"
import { MultipleFileRegistrationResponse, OnboardingCollectiviteResponse, OnboardingSEMResponse, WorkflowProcessResultResponse } from "../../../model/dto/response"
import { CategorieEtablissement } from "../../../model/entities/categorie-etablissement"
import { WorkflowType } from "../../../model/entities/workflow-type"
import { referenceService } from "../../../services/reference-service"
import { OnboardingOrganisation } from "./onboarding-organisation"
import { OnboardingSEM } from "./onboarding-SEM"


type SwitchOnboardingTypeEtablissementProps = {
    subscriptionStepResponse: OnboardingCollectiviteResponse
}

export function SwitchOnboardingTypeEtablissement({subscriptionStepResponse}: SwitchOnboardingTypeEtablissementProps) {
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    const [elements, setElements] = useState<TimelineElement[]>([])
    const [activeStepKey, setActiveStepKey] = useState<string>(subscriptionStepResponse.NextStepKey!)
    const [lastStepResponse, setLastStepResponse] = useState<OnboardingSEMResponse | OnboardingCollectiviteResponse>()

    useEffect(() => {
        if(loginProps.user?.Etablissement?.CategorieCode === CategorieEtablissement.COLLECTIVITE || loginProps.user?.Etablissement?.CategorieCode === CategorieEtablissement.ETABLISSEMENT_PUBLIC) {
            const body = {
                WorkflowTypeCode: WorkflowType.ONBOARDING_PORTEUR_ORGANISATION
              }
              referenceService.getWorkflowStepListByWorkflowTypeCode(body)
            .then(response => {
                const workflowListRes: TimelineElement[] | undefined = response.WorkflowStepList && response.WorkflowStepList.map(res => {
                    return {
                        key: res.Key,
                        dataMainInfo: res.Libelle,
                        order:res.Order
                    } as TimelineElement
                })
                setElements(workflowListRes ?? [])
            })
        }
        else if(loginProps.user?.Etablissement?.CategorieCode === CategorieEtablissement.SOCIETE) {
            const body = {
                WorkflowTypeCode: WorkflowType.ONBOARDING_PORTEUR_SOCIETE
              }
              referenceService.getWorkflowStepListByWorkflowTypeCode(body)
            .then(response => {
                const workflowListRes: TimelineElement[] | undefined = response.WorkflowStepList && response.WorkflowStepList.map(res => {
                    return {
                        key: res.Key,
                        dataMainInfo: res.Libelle,
                        order:res.Order
                    } as TimelineElement
                })
                setElements(workflowListRes ?? [])
            })
        }
    }, [])
    
    if(loginProps.user?.Etablissement?.CategorieCode === CategorieEtablissement.COLLECTIVITE || loginProps.user?.Etablissement?.CategorieCode === CategorieEtablissement.ETABLISSEMENT_PUBLIC) {     
        const onNextStepSuccess = (response: OnboardingCollectiviteResponse) => {
            if(response.IsTraitementOk) {
                setActiveStepKey(response.NextStepKey!)
                setLastStepResponse(response)
            }
        }

        const onSubmitPiecesJustificativesSuccess = (response: MultipleFileRegistrationResponse) => {
            if(response.IsTraitementOk) {
                setActiveStepKey(response.NextStepKey!)
            }
        }

        const onNextStep = (response: WorkflowProcessResultResponse) => {
            if(response.IsTraitementOk){
              setActiveStepKey(response.NextFlowStepCode!)
              setLastStepResponse(response)
            }
        }

        return (
            <>
                <HorizontalTimeline
                    elements={elements}
                    activeElementKey={activeStepKey}
                    lastElementKey={FlowStep.COMPLET}
                    backgroundColor="#fff !important"
                />
                <OnboardingOrganisation
                    activeStepKey={activeStepKey}
                    onSubmitVerification={onNextStepSuccess}
                    onSubmitPiecesJustificatives={onSubmitPiecesJustificativesSuccess}
                    onNextStep={onNextStep}
                    lastStepResponse={lastStepResponse}
                />
            </>
        )
    } else if(loginProps.user?.Etablissement?.CategorieCode === CategorieEtablissement.SOCIETE) {
        const onNextStepSuccess = (response: OnboardingSEMResponse) => {
            if(response.IsTraitementOk) {
                setActiveStepKey(response.NextStepKey!)
                setLastStepResponse(response)
            }
        }

        const onSubmitPiecesJustificativesSuccess = (response: MultipleFileRegistrationResponse) => {
            if(response.IsTraitementOk) {
                setActiveStepKey(response.NextStepKey!)
            }
        }

        const onNextStep = (response: WorkflowProcessResultResponse) => {
            if(response.IsTraitementOk){
              setActiveStepKey(response.NextFlowStepCode!)
              setLastStepResponse(response)
            }
        }
        
        return (
            <>
                <HorizontalTimeline
                        elements={elements}
                        activeElementKey={activeStepKey}
                        lastElementKey={FlowStep.COMPLET}
                        backgroundColor="#fff !important"
                />
                <OnboardingSEM
                    activeElementKey={activeStepKey}
                    onNextStep={onNextStep}
                    onSubmitVerification={onNextStepSuccess}
                    onSubmitPiecesJustificatives={onSubmitPiecesJustificativesSuccess}
                    lastStepResponse={lastStepResponse}
                />
            </>
        )
    }

    return (
        <>
        </>
    )
}