import React, { useEffect, useState } from 'react'
import { HeroActionBandeau } from './detail/parts/hero-action-bandeau/hero-action-bandeau'
import { Card, Container } from 'semantic-ui-react'
import Agreement from '../pages/pages-components/agrement/agreement'
import RejoignezUneCommuEngagee from '../pages/pages-components/call-to-action/rejoignez-une-commu-engagee'
import { ProjetListItem } from '../../model/dto/projet-list-item'
import { trackPromise } from 'react-promise-tracker'
import { publicService } from '../../services/public-service'
import { LoadingSpinner } from '../generic/loading-spinner'
import { ProjetLine } from './projet-line'
import { StatutProjet } from '../../model/entities/statut-projet'

export default function MecenatProjetsList() {
  const [projetList, setProjetList] = useState<ProjetListItem[]>([])

  useEffect(()=> {
    trackPromise(publicService.getProjetList(true)
        .then(response => {
            setProjetList(response.ProjetList!)
        }))
  }, [])

    const isCardClickable = (projet: ProjetListItem) => {
      if(projet.StatutDonProjetCode === StatutProjet.A_VENIR){
        return false
      }
      return true
    }

  return (
    <div>
       <HeroActionBandeau 
          classPage="projets"
          title="Engagez votre entreprise"
        />
        <Container>
            <div>
              <LoadingSpinner>
                  <Card.Group itemsPerRow={3} doubling stackable>
                  {projetList.map((projetItem) => (
                      <ProjetLine
                          key={projetItem.Id}
                          projet={projetItem}
                          isCardClickable={isCardClickable(projetItem)}
                          isDon={true}
                      />
                  ))}
                </Card.Group>
              </LoadingSpinner>
            </div>

           <div style={{marginTop : '14vh'}}>
              <Agreement/>
            </div>
            <div style={{marginTop:'100px', marginBottom: '80px'}}>
              <RejoignezUneCommuEngagee width={88} isButtonEmpty/>
            </div>        
        </Container>
    </div>
  )
}
