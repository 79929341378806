export interface CategorieEtablissement {
    Code?: string,
    Libelle?: string
}

const COLLECTIVITE: string = "COL"
const SOCIETE: string = "SOC"
const ETABLISSEMENT_PUBLIC: string = "ETP"

export const CategorieEtablissement = {
    COLLECTIVITE,
    SOCIETE,
    ETABLISSEMENT_PUBLIC
}