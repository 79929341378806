import isLeapYear from "dayjs/plugin/isLeapYear";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useSelector } from "react-redux";
import { Container, Grid, Table } from "semantic-ui-react";
import { InvestissementHistoryResponse, MailUpdateResponse, MultipleFileRegistrationResponse, OnboardingCollectiviteResponse, OnboardingSEMResponse, ProfilCode, RemboursementHistoryResponse, RetraitHistoryResponse, UserInfoResponse } from "../../../model/dto/response";
import { EtablissementPieceJustificative } from "../../../model/entities/etablissement-piece-justificative";
import { Pays } from "../../../model/entities/pays";
import { Profil } from "../../../model/entities/profil";
import { TypePieceByProfil } from "../../../model/entities/type-piece-by-profil";
import { AuthenticationState } from "../../../model/state/authentication-state";
import { userService } from "../../../services/authentication-redux-service";
import { investmentService } from "../../../services/investment-service";
import { referenceService } from "../../../services/reference-service";
import { ApplicationState } from "../../../store";
import { EditableBlock } from "../../generic/editable-block/editable-block";
import { LightEditableBlock } from "../../generic/editable-block/light-editable-block";
import { LoadingSpinner } from "../../generic/loading-spinner";
import { ChangeMailFormAdmin } from "../../login/change-mail-form-admin";
import { SubscriptionAdresseEtablissement } from "../../login/subscription-adresse-etablissement";
import { SubscriptionAdresseRepresentant } from "../../login/subscription-adresse-representant";
import { SubscriptionCollectiviteFormIdentification } from "../../login/subscription-collectivite-form-identification";
import { SubscriptionCollectiviteFormRepresentants } from "../../login/subscription-collectivite-form-representants";
import { SubscriptionIdentiteRepresentantForm } from "../../login/subscription-identite-representant-form";
import { PieceJustifLine } from "../../profil/piece-justif-line";
import { ProfilCompletionHeader } from "../../profil/profil-completion-header";
import { UpdateProfilStatusForm } from "../form/update-profil-status-form";
import { DetailOperation } from "./detail-operations";
import { OnboardingInvestisseurMoralPorteurProjetDocumentListForm } from "../../login/onboarding-investisseur-moral-porteur-projet-document-list-form";
import { BlockAccountModal } from "../form/block-account-modal";
import { CategorieEtablissement } from "../../../model/entities/categorie-etablissement";
import { SubscriptionSEMFormBeneficiaires } from "../../login/subscription-sem-form-beneficiaires";
import { Beneficiaire } from "../../../model/entities/beneficiaire";

var dayjs = require("dayjs");
dayjs.extend(isLeapYear); // use plugin
dayjs.locale("fr-ch"); // use locale

interface Props {
  userId?: number
}

export function CollectiviteDetail({
  userId
}: Props) {
  const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

  const [userInfo, setUserInfo] = useState<UserInfoResponse>()
  const [typePiecesJustifList, setTypePiecesJustifList] = useState<TypePieceByProfil[]>([])
  const [userPieceJustifList, setUserPieceJustifList] = useState<EtablissementPieceJustificative[]>([])
  const [paysList, setPaysList] = useState<Pays[]>([])
  const [mail, setMail] = useState<string>()
  const [investHistoryList, setInvestHistoryList] = useState<InvestissementHistoryResponse[]>([])
  const [remboursementHistoryList, setRemboursementHistoryList] = useState<RemboursementHistoryResponse[]>([])
  const [retraitHistoryList, setRetraitHistoryList] = useState<RetraitHistoryResponse[]>([])
  const [reloadOperations, setReloadOperations] = useState<boolean>(false)
  const [beneficiairesList, setBeneficiairesList] = useState<Beneficiaire[]>([])
  
  useEffect(() => {
    trackPromise(userService
      .getUserInfoForAdmin(userId, loginProps?.oauth)
      .then((response) => {
        setUserInfo(response)
        setBeneficiairesList(response.BeneficiairesList ?? [])
        setReloadOperations(true)
      }));

    trackPromise(userService
      .getTypePieceUserForAdmin(userId, loginProps?.oauth)
      .then((response) => {
        if (response.IsTraitementOk) {
          setTypePiecesJustifList(response.TypePieceList!)
        }
      }));

    trackPromise(userService
      .getUserPiecesJustifListForAdmin(userId!, loginProps?.oauth)
      .then((response) => {
        if (response.IsTraitementOk) {
          setUserPieceJustifList(response.EtablissementPieceJustifList!)
        }
      }));

      trackPromise(referenceService
        .getAllPays()
        .then((response) => {
          if (response.IsTraitementOk) {
            setPaysList(response.PaysList!)
          }
        }));
  }, [])

  useEffect(() => {
    if(reloadOperations) {
      if(userInfo?.ProfilCode === Profil.INVESTISSEUR) {
        trackPromise(investmentService.getInvestmentHistoryByAdmin(userId, loginProps?.oauth)
        .then(response => {
          setInvestHistoryList(response.InvestissementHistoryResponseList)
        }))

        trackPromise(investmentService.getRemboursementHistoryByAdmin(userId, loginProps?.oauth)
        .then(response => {
          setRemboursementHistoryList(response.RemboursementHistoryResponseList)
        }))

        trackPromise(investmentService.getRetraitHistoryByAdmin(userId, loginProps?.oauth)
        .then(response => {
          setRetraitHistoryList(response.RetraitHistoryResponseList)
        }))
        
      trackPromise(userService
        .getUserPiecesJustifListForAdmin(userId!, loginProps?.oauth)
        .then((response) => {
          if (response.IsTraitementOk) {
            setUserPieceJustifList(response.EtablissementPieceJustifList!)
          }
        }));
      }
      trackPromise(userService
        .getUserInfoForAdmin(userId, loginProps?.oauth)
        .then((response) => {
          setUserInfo(response)
        }));
      

      setReloadOperations(false)
    }
  }, [reloadOperations])

  const adresseMail = () => {
    return (
        <div className="form-group">
          <p>Adresse mail: {userInfo?.Mail}</p>
          <p>Statut: {!userInfo?.IsMailValid && "non"} validée</p>
        </div>
    );
  }

  const identite = () => {
    return (
      <div>
        <div>Prénom : {userInfo?.Prenom}</div>
        <div>Nom : {userInfo?.Nom}</div>
        <div>Qualité : {userInfo?.QualiteRepresentant}</div>
        {userInfo?.Civilite? (
          <div>Civilité : {userInfo.Civilite}</div>
        ) : <div></div>}
        <div>
          Date de naissance :{" "}
          {userInfo?.DateNaissance && userInfo?.DateNaissance !== null && dayjs(userInfo?.DateNaissance).format("DD/MM/YYYY")}
        </div>
        <div>Nationalité : {paysList ? paysList.find(p => p.Code === userInfo?.Nationalite)?.Nom : ""}</div>
      </div>
    );
  }

  const etablissement = () => {
    return (
      <div>
        {userInfo?.Etablissement?.CategorieCode === CategorieEtablissement.COLLECTIVITE && 
          <div>
          Type de collectivité : {userInfo?.Etablissement?.TypeCollectiviteDesignation}
          </div>
       }
       
        <div className="form-group">
          Désignation : {userInfo?.Etablissement?.Designation}
        </div>
      </div>
    );
  }

  const representants = () => {
    return (
      <div className="form-group">
        <div className="form-group">
          {userInfo?.CollaborateursList &&
            userInfo?.CollaborateursList.map((user) => {
              return <div key={user.Mail}>{user.Mail}</div>;
            })}
          {(!userInfo?.CollaborateursList ||
            userInfo?.CollaborateursList?.length === 0) && (
            <div>Aucun collaborateur ajouté</div>
          )}
        </div>
      </div>
    );
  }

  const piecesJustificatives = () => {
    return (
      <div>
        Vous avez envoyé {userInfo?.NombrePiecesJustificativesEnvoyees} pièces
        justificatives.
      </div>
    );
  }

  const piecesJustif = () => {
    return (
      <div>
        <ul
          css={{
            padding: "0",
            fontFamily: "Helvetica",
          }}
        >
          {userPieceJustifList &&
            userPieceJustifList?.map((userPiece) => {
              return (
                <PieceJustifLine
                  typePiece={typePiecesJustifList?.find(
                    (type) => type.Id === userPiece.TypePieceByProfilId
                  )}
                  pieceInfo={userPiece}
                />
              );
            })}
        </ul>
      </div>
    );
  }

  const adressePostaleEtablissement = () => {
    return (
      <div>
        <div
          css={{
            marginBottom: "20px",
          }}
        >
          <div>
            {(userInfo?.Etablissement?.LibelleVoieEtablissement &&
              userInfo?.Etablissement?.LibelleVoieEtablissement) || "Non renseignée"}
          </div>
          {userInfo?.Etablissement?.ComplementAdresseEtablissement && (
            <div>{userInfo?.Etablissement?.ComplementAdresseEtablissement}</div>
          )}
          <div
            css={{
              display: "inline-block",
            }}
          >
            {userInfo?.Etablissement?.CodePostalEtablissement}
          </div>
          <div
            css={{
              display: "inline-block",
              marginLeft: "30px",
            }}
          >
            {userInfo?.Etablissement?.CommuneDesignationEtablissement}
          </div>
        </div>
      </div>
    );
  }

  const adressePostaleRepresentant = () => {
    return (
      <div>
        <div
          css={{
            marginBottom: "20px",
          }}
        >
          <div>
            {(userInfo?.Etablissement?.LibelleVoieRepresentant &&
              userInfo?.Etablissement?.LibelleVoieRepresentant) || "Non renseignée" }
          </div>
          {userInfo?.Etablissement?.ComplementAdresseRepresentant && (
            <div>{userInfo?.Etablissement?.ComplementAdresseRepresentant}</div>
          )}
          <div
            css={{
              display: "inline-block",
            }}
          >
            {userInfo?.Etablissement?.CodePostalRepresentant}
          </div>
          <div
            css={{
              display: "inline-block",
              marginLeft: "30px",
            }}
          >
            {userInfo?.Etablissement?.CommuneDesignationRepresentant}
          </div>
        </div>
      </div>
    );
  }

  const beneficiaires = () => {
    return (
       <div style={{padding:"10px"}}>
         {
           beneficiairesList && beneficiairesList.length > 0 ?
             <Table celled padded>
               <Table.Header>
                 <Table.Row>
                   <Table.HeaderCell > </Table.HeaderCell>
                   <Table.HeaderCell textAlign='center'>Nom</Table.HeaderCell>
                   <Table.HeaderCell textAlign='center'>Prenom</Table.HeaderCell>
                   <Table.HeaderCell textAlign='center'>Date de Naissance</Table.HeaderCell>
                 </Table.Row>
               </Table.Header>
               <Table.Body>
                 {beneficiairesList?.map((beneficiaire, index) =>
                   <Table.Row>
                     <Table.Cell textAlign='center'>{index + 1}</Table.Cell>
                     <Table.Cell textAlign='center'>{beneficiaire.Nom}</Table.Cell>
                     <Table.Cell textAlign='center'>{beneficiaire.Prenom}</Table.Cell>
                     <Table.Cell textAlign='center'>{new Date(beneficiaire.DateNaissance!).toLocaleDateString()}</Table.Cell>
                   </Table.Row>
                 )}
               </Table.Body>
             </Table>
             : <h4 css={{textAlign: "center"}}>Aucun bénéficiaire effectif</h4>
         }
       </div>
   )
 }

  return (
    <Container css={{
      marginTop: '30px'
    }}>
        <LoadingSpinner>
          <LightEditableBlock 
              title="Description"
              editable={loginProps.oauth?.profilCode === ProfilCode.VILLYZ}
              editor={(close) => (
                <UpdateProfilStatusForm 
                    userInfo = {userInfo} 
                    close = {()=>{
                        close()
                    }}
                  />
              )}
          >
            <ProfilCompletionHeader
                status={userInfo?.OnboardingStatus}
            />
          </LightEditableBlock>
          <EditableBlock
            title="Blocage du compte"
            editable
            editor={(close) => (
              <BlockAccountModal 
                userId={userId ?? 0}
                isBloque={userInfo?.IsBloque ?? false}
                onSubmitSuccess={() => {
                  close()
              }}
              />
            )}
          >
            <p>L'utilisateur est actuellement {userInfo?.IsBloque ? "bloqué" : "actif"}</p>
          </EditableBlock>
          <Grid stackable doubling>
            <Grid.Column width={8}>
              <EditableBlock
                title="Adresse mail"
                editable={true}
                editor={(close) => (
                  <ChangeMailFormAdmin
                  userId = {userId}
                  onSubmitSuccess={(
                    response: MailUpdateResponse
                  ) => {
                    setMail(response.Mail)
                    close();
                  }}
                />
                )}
              >
                {adresseMail()}
              </EditableBlock>
              <EditableBlock
                title="Identité"
                editable={true}
                editor={(close) => (
                  <SubscriptionIdentiteRepresentantForm
                    userId={userId}
                    user={userInfo}
                    onSubmitSuccess={(
                      response: OnboardingCollectiviteResponse
                    ) => {
                      setUserInfo(response.UserInfo)
                      close();
                    }}
                  />
                )}
              >
                {identite()}
              </EditableBlock>
              <EditableBlock
                title="Adresse de la collectivité"
                editable={true}
                editor={(close) => (
                  <SubscriptionAdresseEtablissement
                    userInfo={userInfo}
                    onSubmitSuccess={(
                      response: OnboardingCollectiviteResponse
                    ) => {
                      setUserInfo(response.UserInfo)
                      close();
                    }}
                  />
                )}
              >
                {adressePostaleEtablissement()}
              </EditableBlock>
              <EditableBlock
                title="Adresse représentant"
                editable={true}
                editor={(close) => (
                  <SubscriptionAdresseRepresentant
                    userInfo={userInfo}
                    onSubmitSuccess={(
                      response: OnboardingCollectiviteResponse
                    ) => {
                      setUserInfo(response.UserInfo)
                      close();
                    }}
                  />
                )}
              >
                {adressePostaleRepresentant()}
              </EditableBlock>
            </Grid.Column>
            <Grid.Column width={8}>
              <EditableBlock
                title="Etablissement"
                editable={true}
                editor={(close) => 
                <SubscriptionCollectiviteFormIdentification
                  userInfo={userInfo}
                  onSubmitSuccess={(
                    response: OnboardingCollectiviteResponse
                  ) => {
                    setUserInfo(response.UserInfo)
                    if (loginProps?.user?.ProfilCode === ProfilCode.PORTEUR_PROJET)
                    {
                      trackPromise(userService
                          .getTypePieceForUser(loginProps?.oauth?.userId, loginProps?.oauth)
                          .then((response) => {
                            if (response.IsTraitementOk) {
                              setTypePiecesJustifList(response.TypePieceList!)
                            }
                          }));
                  
                      trackPromise(userService
                          .getUserPiecesJustifList(loginProps?.oauth?.userId, loginProps?.oauth)
                          .then((response) => {
                          if (response.IsTraitementOk) {
                            setUserPieceJustifList(response.EtablissementPieceJustifList!)
                          }
                          }));
                      close();
                    }
                    else {
                      trackPromise(userService
                          .getTypePieceUserForAdmin(userInfo?.UserId, loginProps?.oauth)
                          .then((response) => {
                          if (response.IsTraitementOk) {
                            setTypePiecesJustifList(response.TypePieceList!)
                          }
                          }));
                  
                      trackPromise(userService
                          .getUserPiecesJustifListForAdmin(userInfo?.UserId!, loginProps?.oauth)
                          .then((response) => {
                          if (response.IsTraitementOk) {
                              setUserPieceJustifList(response.EtablissementPieceJustifList!)
                          }
                          }));
                      close();

                    }
                  }}
                  
                />}
              >
                {etablissement()}
              </EditableBlock>
              <EditableBlock
                title="Collaborateurs"
                editable={true}
                editor={(close) => (
                  <SubscriptionCollectiviteFormRepresentants
                    userInfo={userInfo}
                    existingCollaborateursList={userInfo?.CollaborateursList}
                    isOnboarding={false}
                    close = {close}
                    onSubmitSuccess={(
                      response: OnboardingCollectiviteResponse
                    ) => {
                      setUserInfo(response.UserInfo)
                      close();
                    }}
                  />
                )}
              >
                {representants()}
              </EditableBlock>
              <EditableBlock
                title="Pièces justificatives"
                editable={true}
                editor={(close) => (
                  <OnboardingInvestisseurMoralPorteurProjetDocumentListForm
                    userId={userId}
                    onActionSuccess={(
                      response: MultipleFileRegistrationResponse
                    ) => {
                      setUserInfo(response.UserInfo)
                      setUserPieceJustifList(response.EtablissementPieceList!)
                      close();
                    }}
                  />
                )}
              >
                {piecesJustif()}
              </EditableBlock>
            </Grid.Column>
          </Grid>
          {/* UNIQUEMENT SI SOCIETE ! */}
          {userInfo?.Etablissement?.CategorieCode === CategorieEtablissement.SOCIETE && 
          <EditableBlock
              title="Bénéficiaires effectifs"
              editable
              editor={(close) => (
                <SubscriptionSEMFormBeneficiaires
                  userId={userInfo.Etablissement?.Id}
                  beneficiaires={beneficiairesList} 
                  isOnboarding = {false}
                  onSubmitSuccess={(response: OnboardingSEMResponse)=>{
                    setUserInfo(response.UserInfo)
                    setBeneficiairesList(response.UserInfo?.BeneficiairesList ?? [])
                    close();
                  }}     
                  onCancel={close}                 
                />
              )}
            >
              {beneficiaires()}
          </EditableBlock>
          }
          {userInfo?.ProfilCode === Profil.INVESTISSEUR && (
            <DetailOperation 
              userInfo={userInfo}
              userPieceJustifList={userPieceJustifList}
              investHistoryList={investHistoryList}
              remboursementHistoryList={remboursementHistoryList}
              retraitHistoryList={retraitHistoryList}
              askReload={(value: boolean) => setReloadOperations(value)}
            />
          )}
        </LoadingSpinner>
    </Container>
  );
}

