import React from 'react'
import { SubmitButton } from '../generic/submit-button'
import { ModePaiement } from '../../model/entities/mode-paiement'
import {  InvestResponse } from '../../model/dto/response'
import { AttentePaiementPane } from './attente-paiement-pane'
import { Icon} from 'semantic-ui-react'

type Props = {
    montant?: string,
    projetId?: number,
    montantAVirer?:string,
    hasUserInvestimentsByVirementEnCours: boolean;
    showAttentePaiement?: boolean;
    isDon?: boolean,
    onSubmitImpossible?: (message: string) => void;
    onClickAttentePaiement?: () => void;
    onVirementSuccess?: (response: InvestResponse) => void;
}

export function VirementClassiquePane({
    montant, 
    projetId, 
    montantAVirer, 
    showAttentePaiement, 
    hasUserInvestimentsByVirementEnCours, 
    isDon,
    onSubmitImpossible, 
    onClickAttentePaiement, 
    onVirementSuccess
}: Props) {

    return (
        <div>
            {showAttentePaiement ? ( 
                <AttentePaiementPane 
                    montant={montant}
                    projetId={projetId}
                    modePaiement={ModePaiement.VIREMENT_CLASSIQUE}
                    onSubmitImpossible={onSubmitImpossible}
                    onInvestSuccess={onVirementSuccess}
                    isDon= {isDon}
                />
            ) 
            : 
            (
            <div style={{display:'flex', flexDirection:'column'}}>
                <p css={{fontWeight: 'bold', fontSize: '16px', marginBottom: '10px'}}>Virement classique</p>
                {hasUserInvestimentsByVirementEnCours ? (
                    <div style={{marginTop:10, display:'flex', justifyContent:'space-between'}}>
                        <div style={{marginRight:10}}>
                        <Icon name='info circle' size='big' color="blue"/>
                        </div>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <p >Vous avez déjà un investissement par virement en cours pour lequel vous avez reçu les informations nécessaires à sa réalisation par email.</p>
                            <p>À la réception de ce dernier vous pourrez de nouveau investir par virement, en attendant vous pouvez réaliser ce paiement par carte bancaire.</p>
                        </div>
                    </div>
                ) : (
                <>
                    <p style={{fontSize: '14px'}}>Afin d'effectuer un investissement via virement classique vous devez :</p>
                    <div style={{display : 'flex', marginTop:'5px', alignItems:'center'}}>
                        <span style={{marginRight:'10px', minWidth:'24px'}}><img src='/img/icones/iban.svg' alt="icone d'une banque en tant que logo pour l'iban"/></span>
                        <p>Enregistrer auprès de votre banque les coordonnées de votre compte Villyz qui vous seront communiquées à la prochaine étape et par email.</p>
                    </div>
                    <div style={{display : 'flex', marginTop:'15px', alignItems:'center'}}>
                        <span style={{marginRight:'10px', minWidth:'24px'}}><img src='/img/icones/transfert.svg' alt="icone de deux flèches en sens inversés l'une au dessus de l'autre, en tant que logo pour le transfert"/></span>
                        <p >Effectuer votre virement pour un montant de {montantAVirer} € vers cet IBAN</p>
                    </div>
                    <div style={{marginTop:'35px', alignSelf:'end'}}>
                        <SubmitButton 
                            label={isDon ? "Confirmer ma demande de don": "Confirmer ma demande d'investissement"}
                            name="paymentButton"
                            onClick={onClickAttentePaiement}
                        />
                    </div>
                </>
                )}
                
            </div>
            )}
        </div>
    )
}