import React from "react";
import { CategorieEtablissement } from "../../model/entities/categorie-etablissement";
import { TypeCollectivite } from "../../model/entities/type-collectivite";
import "./projet-localisation.css";

type Props = {
    typeCollectiviteCode?: string,
    codeDepartement?: string,
    categorieEtablissement?: string,
    nomLocalite?: string,
    nomEtablissement?: string,
};

export function ProjetLocalisation({ typeCollectiviteCode, codeDepartement, nomEtablissement, nomLocalite, categorieEtablissement}: Props) {
    return (
        <>
            {(categorieEtablissement === CategorieEtablissement.COLLECTIVITE && typeCollectiviteCode === TypeCollectivite.REGION || typeCollectiviteCode === TypeCollectivite.EPCI) && (
                <div className="cardContent">
                    <span>{nomEtablissement}</span>
                </div>
            )}

            {categorieEtablissement === CategorieEtablissement.COLLECTIVITE && typeCollectiviteCode === TypeCollectivite.DEPARTEMENT && (
                <div className="cardContent">
                    <span>{nomEtablissement}</span>
                    &nbsp;(<span>{codeDepartement}</span>)
                </div>
            )}

            {categorieEtablissement === CategorieEtablissement.COLLECTIVITE && typeCollectiviteCode === TypeCollectivite.COMMUNE && (
                <div className="cardContent">
                    <span>{nomEtablissement}</span>
                    &nbsp;(<span>{codeDepartement}</span>)
                </div>
            )}

            {categorieEtablissement === CategorieEtablissement.ETABLISSEMENT_PUBLIC && (
                <div className="cardContent">
                    <span>{nomEtablissement} -&nbsp;</span>
                    <span>{nomLocalite}</span>
                    &nbsp;(<span>{codeDepartement}</span>)
                </div>
            )}

            {categorieEtablissement === CategorieEtablissement.SOCIETE && (
                <div className="cardContent">
                    <span>{nomEtablissement}</span>
                </div>
            )}
        </>
    )
    



  




    
            
}