import React, {useEffect, useRef, useState} from "react"
import { Button, Form, Icon, Table } from 'semantic-ui-react';
import { SubmitButton } from "../generic/submit-button";
import { userService } from "../../services/authentication-redux-service"
import { ApplicationState } from "../../store";
import { connect, useDispatch, useSelector } from "react-redux";
import { AuthenticationState } from "../../model/state/authentication-state";
import { FormErrorHeader } from "../generic/form-error-header";
import { InputField } from "../generic/input-field";
import { Pays } from "../../model/entities/pays";
import { referenceService } from "../../services/reference-service";
import { RegisterBeneficiairesSEMBody } from "../../model/dto/body/register-beneficiaires-sem";
import { DropdownListFieldNew } from "../generic/dropdown-list-field";
import { CheckBoxSimpleField } from "../generic/checkbox-simple-field/checkbox-simple-field";
import { SimpleButton } from "../generic/simple-button";
import { CollectiviteSelector } from "../generic/collectivite-selector/collectivite-selector";
import { Collectivite } from "../../model/entities/profil";
import { Beneficiaire } from "../../model/entities/beneficiaire";
import { AuthenticationAction, CHANGE_IDENTITY } from "../../actions/authentication-actions";
import { OnboardingInvestisseurResponse, ProfilCode } from "../../model/dto/response";
import { isLegalAgeDate } from "../../helpers/date-helper";
import { FlowStep } from "../../model/entities/flow-step";
import {isValid} from 'date-fns'
import DateMaskFieldNewV2 from "../generic/date-mask-field-2";


type FieldData = {
    name?: string,
    value?: any,
    isValid?: boolean
}

type FormData = {
    nom: FieldData,
    prenom: FieldData,
    dateNaissance: FieldData,
    paysNaissanceCode: FieldData,
    communeNaissanceId: FieldData,
    nationalite: FieldData,
    nomDeCommuneNaissanceEtrangere: FieldData,
    libelleVoie: FieldData,
    adresseLigne2: FieldData,
    codePostal: FieldData,
    communeId: FieldData,
    paysCode: FieldData,
    nomDeCommuneEtrangere: FieldData,
    confirmationDeclaration: FieldData
}

type Props = {
    beneficiaires?: Beneficiaire[],
    isOnboarding?: boolean,
    workflowTypeCode?: string,
    userId?: number,
    onSkipStep?: (response: Response) => void,
    onSubmitSuccess: (response: Response) => void,
    onCancel?: () => void
}

enum Action {
    Ajouter = "Ajouter ce bénéficiaire effectif",
    Modifier = "Modifier ce bénéficiaire effectif"
}

function SubscriptionSEMFormBeneficiaires({ beneficiaires, isOnboarding = true, workflowTypeCode, userId, onSubmitSuccess, onSkipStep, onCancel}: Props) {
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)
    const dispatch = useDispatch()

    const validateMandatory = (value: any): boolean => {
        return value && value !== ""
    }

    const validatePostalCode = (value: any): boolean => {
        const regex = /^[0-9]{5}$/g;
        return regex.test(value);
    };

    const validateConfirmationDeclaration = (value: any): boolean => {
        if(value === undefined) {
            return false
        } else {
            return value
        }
    }

    const [beneficiairesList, setBeneficiairesList] = useState<Beneficiaire[]>(beneficiaires || [])
    const [action, setAction] = useState<string>(Action.Ajouter)
    const beneficiaireToBeUpdated = useRef<Beneficiaire>()

    const initialForm : FormData = {
        prenom: {value: "", name: 'prenom', isValid: true},
        nom: {value: "", name: 'nom', isValid: true},
        dateNaissance: {value: "", name: 'dateNaissance', isValid: true},
        paysNaissanceCode : {value: "FR" , name: "paysNaissanceCode", isValid: true},
        communeNaissanceId: {value: "", name: 'communeNaissanceId', isValid: true},
        nationalite: {value: "FR", name: 'nationalite', isValid: true},
        nomDeCommuneNaissanceEtrangere : {value : '', name: "nomDeCommuneNaissanceEtrangere", isValid: true},
        libelleVoie: {value: "", name: 'libelleVoie', isValid: true},
        adresseLigne2: {value: "", name: 'adresseLigne2', isValid: true},
        codePostal: {value: "", name: 'codePostal', isValid: true},
        communeId: {value: "", name: 'communeId', isValid: true},
        nomDeCommuneEtrangere : {value : "", name: "nomDeCommuneEtrangere", isValid: true},
        paysCode: {value: "FR", name: 'paysCode', isValid: true},
        confirmationDeclaration: {value: false, name: 'confirmationDeclaration', isValid: true}
    }

    const [formSubmitted, setFormSubmitted] = useState(false)
    const [isFormError, setIsFormError] = useState(false)
    const [form, setForm] = useState<FormData>(initialForm)
    const [warning, setWarning] = useState<boolean>(false)
    const [isFormValidated, setIsFormValidated] = useState<boolean>(false)

    const [paysList, setPaysList] = useState<Pays[]>([])
    const [isDateError, setIsDateError] = useState<boolean>(false)
    const [dateErrorMessage, setDateErrorMessage] = useState<string>('')
    
    useEffect(() => {
        referenceService.getAllPays()
        .then(response => {
            setPaysList(response.PaysList!)
        })
    }, [])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean): void => {
        const fieldValue: string = e.target.value;
        setIsFormError(false)
        setFormSubmitted(false)
        setForm({...form, [fieldName!]: {value: fieldValue, name: fieldName, isValid: isValid}})
    }

    const convertDateToStringInput = (value?: string ) => {
        const backEndFormatDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/
        if((value && isValid(value)) || (value && backEndFormatDateRegex.test(value))) {
            let dateLocale = (new Date(value)).toLocaleDateString()
            const validDate = dateLocale.replaceAll('/', '')
            return validDate.toString()
        }else {
            return value  
        }
    }

    const handleDateChange = (date: string ): void => {
        const fieldName = "dateNaissance"
        setIsDateError(false)
        setIsFormError(false)
        setFormSubmitted(false)
        if(date.length === 8){
            const day = parseInt(date.substring(0,2))
            const month = parseInt(date.substring(2,4)) - 1
            const year = parseInt(date.substring(4))
            const newDate = new Date(Date.UTC(year, month, day))
            if(isValid(newDate)){
                setIsFormError(false)
                setFormSubmitted(false)
                setIsDateError(false)
                setForm({...form, [fieldName]: { value: newDate, name: fieldName, isValid: true }})
            } else {
                setIsDateError(true)
            }
        } else {
            setForm({...form, [fieldName]: { value: date, name: fieldName, isValid: true }})
        }   
    }

    const isFormValid = (): boolean => {
        return validateMandatory(form.prenom.value) && 
            validateMandatory(form.nom.value) && 
            validateDateNaissance(form.dateNaissance.value) &&
            validateMandatory(form.paysNaissanceCode.value) &&
            (validateMandatory(form.communeNaissanceId.value) ||
            validateMandatory(form.nomDeCommuneNaissanceEtrangere.value)) &&
            validateMandatory(form.nationalite.value) &&
            validateMandatory(form.libelleVoie.value) &&
            validatePostalCode(form.codePostal.value) &&
            (validateMandatory(form.communeId.value) ||
            validateMandatory(form.nomDeCommuneEtrangere.value)) &&
            validateMandatory(form.paysCode.value)
    }

    const onFormNotValid = (): void => {
        if(beneficiairesList.length === 0 || (beneficiairesList.length > 0 && beneficiairesList.filter(beneficiaires => beneficiaires.IsActive).length === 0) ) {
            setWarning(true)
        } else {
            setWarning(false)
        }

        if(!validateConfirmationDeclaration(form.confirmationDeclaration.value)) {
            setIsFormError(true)
        }
    }

    const onSubmitError = (): void => {
    }

    const handleInputChangeNew = (
        e: React.ChangeEvent<HTMLInputElement>,
        fieldName?: string,
        isValid?: boolean
      ): void => {
        const fieldValue: string = e.target.value;
    
        if (fieldName) {
          const newField: FieldData = {
            [fieldName]: { name: fieldName, value: fieldValue, isValid: isValid },
          };
          setForm({ ...form, ...newField });
          setIsFormError(false);
          setWarning(false)
        }
    }

    const onAdd = () => {

        if(!isFormValid()) {
            setFormSubmitted(true)
            setIsFormError(true)
            return
        }

        let beneficiaire: Beneficiaire = {
            Nom: form.nom.value,
            Prenom: form.prenom.value,
            DateNaissance: form.dateNaissance.value,
            PaysNaissance: form.paysNaissanceCode.value,
            CommuneNaissanceId: form.communeNaissanceId.value,
            Nationalite: form.nationalite.value,
            NomDeCommuneNaissanceEtrangere: form.nomDeCommuneNaissanceEtrangere.value,
            LibelleVoie: form.libelleVoie.value,
            ComplementAdresse: form.adresseLigne2.value,
            CodePostal: form.codePostal.value,
            CommuneId: form.communeId.value,
            CommuneEtrangere: form.nomDeCommuneEtrangere.value,
            Pays: form.paysCode.value,
            IsActive: true
        }

        if(action === Action.Modifier){
            beneficiaire.Id = beneficiaireToBeUpdated.current?.Id ?? undefined
            const newList = beneficiairesList.map(selectedbeneficaire =>{
                if(selectedbeneficaire === beneficiaireToBeUpdated.current){
                    const updatedBeneficiaire = {
                        ...selectedbeneficaire,
                        ...beneficiaire
                    };
                    return updatedBeneficiaire
                }
                return selectedbeneficaire
            })
            setBeneficiairesList(newList)
        }
        else{
            setBeneficiairesList(prevList => [...prevList, beneficiaire])
            setForm({ ...initialForm})
        }
        setForm({ ...initialForm})
        setAction(Action.Ajouter)
        setWarning(false)
        setIsFormValidated(false)
    }

    const onStartUpdating = (beneficiaire: Beneficiaire)=>{
        setForm({
            ...form,
            prenom: {value: beneficiaire.Prenom, name: 'prenom', isValid: true},
            nom: {value: beneficiaire.Nom, name: 'nom', isValid: true},
            dateNaissance: {value: beneficiaire.DateNaissance, name: 'dateNaissance', isValid: true},
            paysNaissanceCode : {value: beneficiaire.PaysNaissance , name: "paysNaissanceCode", isValid: true},
            communeNaissanceId: {value: beneficiaire.CommuneNaissanceId, name: 'communeNaissanceId', isValid: true},
            nationalite: {value: beneficiaire.Nationalite, name: 'nationalite', isValid: true},
            nomDeCommuneNaissanceEtrangere : {value : beneficiaire.NomDeCommuneNaissanceEtrangere, name: "nomDeCommuneNaissanceEtrangere", isValid: true},
            libelleVoie: {value: beneficiaire.LibelleVoie, name: 'libelleVoie', isValid: true},
            adresseLigne2: {value: beneficiaire.ComplementAdresse, name: 'adresseLigne2', isValid: true},
            codePostal: {value: beneficiaire.CodePostal, name: 'codePostal', isValid: true},
            communeId: {value: beneficiaire.CommuneId, name: 'communeId', isValid: true},
            nomDeCommuneEtrangere : {value : beneficiaire.CommuneEtrangere, name: "nomDeCommuneEtrangere", isValid: true},
            paysCode: {value: beneficiaire.Pays, name: 'paysCode', isValid: true},
            confirmationDeclaration: {value: true, name: 'confirmationDeclaration', isValid: true}
        })
        beneficiaireToBeUpdated.current = beneficiaire
        setAction("Modifier ce bénéficiaire effectif")
        setFormSubmitted(false)
    } 

    const onDelete = (beneficiaire: Beneficiaire)=>{
        const newBeneficiairesList = beneficiairesList.map((beneficiaireToBeRemoved) =>{
            if(beneficiaireToBeRemoved === beneficiaire){
                beneficiaireToBeRemoved.IsActive = false
            }
            return beneficiaireToBeRemoved
        })

        setBeneficiairesList(newBeneficiairesList)
    }
    
    const onSubmitSEMBeneficiaireSuccess = (response: OnboardingInvestisseurResponse) => {
        if(response.UserInfo) {
            const action: AuthenticationAction = { type: CHANGE_IDENTITY, user: response.UserInfo }
            localStorage.setItem("user_info", JSON.stringify(response.UserInfo))
            dispatch(action)
        }
        onSubmitSuccess(response)
    }

    const body: RegisterBeneficiairesSEMBody = {
        Beneficiaires: beneficiairesList,
        ConfirmationDeclaration: form.confirmationDeclaration.value,
        WorkflowTypeCode: workflowTypeCode
    }

    const wrongDateMessage = "Merci de renseigner la date de naissance du bénéficiaire au format JJ/MM/AAAA"
    const legalAgeDateMessage = "Conformément aux CGU vous devez avoir au moins 18 ans pour utiliser les services de la plateforme"

    const validateDateNaissance = (date: Date | string) => {
        if(!date){
            setIsDateError(true)
            setDateErrorMessage(wrongDateMessage)
            return false
        } else {
            if(typeof(date) === 'string'){
                if(date.length === 8){
                    return date = new Date(date)
                }
                setIsDateError(true)
                setDateErrorMessage(wrongDateMessage)
                return false
            }
            if(!isLegalAgeDate(date)) {
                setIsDateError(true)
                setDateErrorMessage(legalAgeDateMessage)
                return false
            }
            setIsDateError(false)
            return true 
        }
    }

    const isValidDate = (dateValue : string) => {
        return dateValue.length === 8
    }

    const skipToNextStepKey = () => {
        const body = {
            WorkflowTypeCode : workflowTypeCode!,
            CurrentFlowStep : FlowStep.BENEFICIAIRES_EFFECTIFS,
            RessourceId:  loginProps?.user?.UserId!,
            IsSkiped: true
        }
        return referenceService.getNextStep(body).then(response => {
          onSkipStep && onSkipStep(response)})
      }
     
    return (
        <div>
            <Form>
                <FormErrorHeader 
                    message={"Veuillez corriger les champs en erreur"}
                    displayed={isFormError}
                /> 
                <>
                    <div css={{
                        padding: "8px",
                        marginBottom: "20px",
                        backgroundColor:"#D1E9F7",
                        border: "solid 1px #005879",
                        borderRadius: "5px",
                        color: "#005879",
                        fontWeight: "bold",
                        display:"flex", 
                        alignItems:"center",
                    }}>
                        <Icon  
                            name="info circle" 
                            size="big" 
                            css={{color:"#005879", flexBasis: "5%"}}
                        />
                        <div css={{fontSize: '12px', flexBasis: "95%"}}>
                            <p>Afin de valider votre inscription nous devons collecter les informations suivantes pour chaque bénéficiaire effectif de votre société.</p>
                            <p css={{marginTop: '-0.67rem !important'}}> Un bénéficiaire effectif est une personne physique détentrice de 25% ou plus du capital ou des droits de votre de la société, ou, à défaut de détention de 25% ou plus, un réprésentant légal de la société.</p>
                        </div>
                       
                    </div>
                    <div className="form-group">
                        <div className="form-group">
                            <h2 css={{marginTop: '2.89rem !important'}}>Liste des bénéficiaires effectifs</h2>
                            {(beneficiairesList.length === 0 || (beneficiairesList.length > 0 && beneficiairesList.filter(beneficiaire => beneficiaire.IsActive).length === 0)) &&
                                <p>Aucun bénéficiaire effectif déclaré, veuillez déclarer tous les bénéficiaires effectifs</p>
                            }
                            {warning && <FormErrorHeader 
                            message={"Veuillez ajouter au moins un bénéficiare effectif pour valider !"}
                            displayed={warning}
                        />}
                        </div>
                        <div css={{padding: '0px 3rem !important'}}>
                            {beneficiairesList && beneficiairesList.filter(beneficiaire => beneficiaire.IsActive).length !== 0 && <Table celled padded>
                                <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell > </Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center'>Nom</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center'>Prenom</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center'>Date de Naissance</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center'>Action</Table.HeaderCell>
                                </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                {beneficiairesList?.filter(beneficiaire => beneficiaire.IsActive).map((beneficiaire, index) =>
                                    <Table.Row key={index}>
                                    <Table.Cell textAlign='center'>{index + 1}</Table.Cell>
                                    <Table.Cell textAlign='center'>{beneficiaire.Nom}</Table.Cell>
                                    <Table.Cell textAlign='center'>{beneficiaire.Prenom}</Table.Cell>
                                    <Table.Cell textAlign='center'>{new Date(beneficiaire.DateNaissance!).toLocaleDateString()}</Table.Cell>
                                    <Table.Cell textAlign='center'>
                                        <div>
                                            <Button 
                                                circular  
                                                icon='pencil'
                                                onClick={()=>onStartUpdating(beneficiaire)}
                                            />
                                            <Button 
                                                circular  
                                                icon='times circle outline'
                                                onClick={() => onDelete(beneficiaire)}
                                            />
                                        </div>
                                    </Table.Cell>
                                    </Table.Row>
                                )}
                                </Table.Body>
                            </Table>
                            }
                        </div>
                    </div>

                    <h2>Ajout d'un bénéficiaire effectif</h2> 
                    <div css={{marginLeft: '40px !important'}}>
                        <h3 css={{marginTop: '30px !important'}}>Identification</h3>
                        <Form.Group>
                            <Form.Field width={5}>
                                <InputField 
                                    data={form.nom}
                                    label="Nom*"
                                    autocomplete="nope"
                                    submitted={formSubmitted}
                                    error="Merci de renseigner le nom du bénéficiaire"
                                    validateField={validateMandatory}
                                    onChange={handleInputChange}
                                />
                            </Form.Field>
                            <Form.Field width={5}>
                                <InputField 
                                    data={form.prenom}
                                    label="Prénom*"
                                    autocomplete="nope"
                                    submitted={formSubmitted}
                                    error="Merci de renseigner le prénom du bénéficiaire"
                                    validateField={validateMandatory}
                                    onChange={handleInputChange}
                                />
                            </Form.Field>
                            <Form.Field width={5}>
                                <DateMaskFieldNewV2
                                data={form.dateNaissance}
                                label="Date de naissance*"
                                formatModel="# # / # # / # # # #"
                                value={convertDateToStringInput(form.dateNaissance.value)}
                                mask="_"
                                type="tel"
                                error={dateErrorMessage}
                                validateField={isValidDate}
                                allowEmptyFormatting
                                onChange={(dateValue) => {handleDateChange(dateValue)}}
                                submitted={formSubmitted}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field width={5}>
                                <DropdownListFieldNew
                                    label="Pays de naissance*"
                                    autocomplete="nope"
                                    field={form.paysNaissanceCode}
                                    placeholder="Pays de naissance"
                                    error="Merci de renseigner le pays de naissance du bénéficiaire"
                                    submitted={formSubmitted}
                                    datasource={paysList.map((c) => ({
                                        text: c.Nom!,
                                        value: c.Code!,
                                    }))}
                                    onChange={(value) =>
                                        handleInputChange(
                                        { target: { value } } as any,
                                        form.paysNaissanceCode.name
                                        )
                                    }
                                    validateField={validateMandatory}
                                />
                            </Form.Field>
                            <Form.Field width={5}>
                                {form.paysNaissanceCode.value === "FR" ? 
                                <CollectiviteSelector
                                    type={Collectivite.COMMUNE}
                                    label="Ville de naissance*"
                                    useCodeUnique={false}
                                    submitted={formSubmitted}
                                    initialCode={form.communeNaissanceId.value}
                                    error="Merci de renseigner la ville de naissance du bénéficiaire"
                                    onChange={(value: any, isValid: boolean) => {
                                        setForm({
                                        ...form,
                                        communeNaissanceId: {
                                            value: value,
                                            name: "communeNaissanceId",
                                            isValid: isValid,
                                        },
                                        });
                                        setIsFormError(false)
                                        setFormSubmitted(false)
                                    }}
                                    validateField={validateMandatory}
                                /> 
                                :
                                <InputField
                                    data={form.nomDeCommuneNaissanceEtrangere}
                                    label="Nom de commune de naissance*"
                                    autocomplete="nope"
                                    submitted={formSubmitted}
                                    onChange={handleInputChangeNew}
                                    error="Merci de renseigner le nom de commune de naissance du bénéficiaire"
                                    validateField={validateMandatory}
                                />}
                            </Form.Field>
                            <Form.Field width={5}>
                                <DropdownListFieldNew
                                    label="Nationalité*"
                                    autocomplete="nope"
                                    field={form.nationalite}
                                    placeholder="Nationalité"
                                    error="Merci de renseigner la nationalité du bénéficiaire"
                                    submitted={formSubmitted}
                                    datasource={paysList.map((c) => ({
                                        text: c.Nom!,
                                        value: c.Code!,
                                    }))}
                                    onChange={(value) =>
                                        handleInputChange(
                                        { target: { value } } as any,
                                        form.nationalite.name
                                        )
                                    }
                                    validateField={validateMandatory}
                                />
                            </Form.Field>
                        </Form.Group>
                        <h3 css={{marginTop: '30px !important'}}>Adresse</h3>
                        <Form.Group>
                            <Form.Field width={15}>
                                <InputField 
                                    data={form.libelleVoie}
                                    label="Adresse*"
                                    autocomplete="nope"
                                    submitted={formSubmitted}
                                    onChange={handleInputChange}
                                    error="Merci de renseigner l'adresse du bénéficiaire"
                                    validateField={validateMandatory}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Field width={15}>
                            <InputField 
                                data={form.adresseLigne2}
                                label="Complément d'adresse"
                                autocomplete="nope"
                                submitted={formSubmitted}
                                onChange={handleInputChange}
                            />
                        </Form.Field>
                        <Form.Group>
                            <Form.Field width={5}>
                                <InputField 
                                    data={form.codePostal}
                                    label="Code postal*"
                                    autocomplete="nope"
                                    submitted={formSubmitted}
                                    onChange={handleInputChange}
                                    error="Merci de renseigner le code postal à 5 chiffres du bénéficiaire"
                                    validateField={validatePostalCode}
                                />
                            </Form.Field>
                            <Form.Field width={6}>
                                <DropdownListFieldNew
                                    label="Pays*"
                                    autocomplete="nope"
                                    field={form.paysCode}
                                    placeholder="Pays"
                                    error="Merci de renseigner le pays du bénéficiaire"
                                    submitted={formSubmitted}
                                    datasource={paysList.map((c) => ({
                                        text: c.Nom!,
                                        value: c.Code!,
                                    }))}
                                    onChange={(value) =>
                                        handleInputChange(
                                        { target: { value } } as any,
                                        form.paysCode.name
                                        )
                                    }
                                    validateField={validateMandatory}
                                />
                            </Form.Field>
                            <Form.Field width={4}>
                                {form.paysCode.value === "FR" ? 
                                    <CollectiviteSelector
                                        type={Collectivite.COMMUNE}
                                        label="Ville*"
                                        useCodeUnique={false}
                                        submitted={formSubmitted}
                                        initialCode={form.communeId.value}
                                        error="Merci de renseigner la ville du bénéficiaire"
                                        onChange={(value: any, isValid: boolean) => {
                                            setForm({
                                            ...form,
                                            communeId: {
                                                value: value,
                                                name: "communeId",
                                                isValid: isValid,
                                            },
                                            });
                                            setIsFormError(false);
                                            setWarning(false)

                                        }}
                                        validateField={validateMandatory}
                                    /> 
                                    :
                                    <InputField
                                        data={form.nomDeCommuneEtrangere}
                                        label="Nom de commune Étrangère*"
                                        autocomplete="nope"
                                        submitted={formSubmitted}
                                        onChange={handleInputChangeNew}
                                        error="Merci de renseigner la commune étrangère du bénéficiaire"
                                        validateField={validateMandatory}
                                    />
                                }
                            </Form.Field>
                        </Form.Group>
                        <div css={{display:"inline-block", marginBottom:"25px", marginTop:"12px"}}>
                            <SimpleButton 
                                className="form-group"
                                label= {action}
                                onClick={onAdd}
                            />
                            {action === Action.Modifier && 
                            <SimpleButton
                                label="Annuler"
                                onClick={() =>{
                                    setForm({ ...initialForm})
                                    setAction(Action.Ajouter)
                                }}
                            />
                            }
                        </div>
                    </div>
                </>
                <Form.Group>
                    <Form.Field width={16}>
                        <CheckBoxSimpleField
                            data={form.confirmationDeclaration}
                            label="Je confirme avoir déclaré toutes les informations relatives aux bénéficiaires effectifs"
                            error="Merci de confirmer cette mention"
                            submitted={isFormValidated}
                            onChange={(value: any, isValid: boolean) => {
                                handleInputChange(
                                { target: { value } } as any,
                                form.confirmationDeclaration.name,
                                isValid
                                );
                            }}
                            validateField={validateConfirmationDeclaration}
                        />
                    </Form.Field>
                </Form.Group>
                <div css={{paddingBottom : "10px"}}>*champ obligatoire</div>

                <div className="button-bar">
                    <SubmitButton 
                        data={body}
                        label="Valider"
                        onPreSubmit={() => {setIsFormValidated(true)}}
                        action={loginProps?.oauth?.profilCode === ProfilCode.VILLYZ ? () => userService.registerBeneficiairesSEMByAdmin(body,loginProps?.oauth?.userId, userId, loginProps?.oauth) : () => userService.registerBeneficiairesSEM(body, loginProps?.user?.UserId, loginProps?.user?.Etablissement?.Id, loginProps?.oauth) }
                        validateForm={()=>  beneficiairesList.length > 0 && beneficiairesList.filter(beneficiaires => beneficiaires.IsActive).length > 0 && validateConfirmationDeclaration(form.confirmationDeclaration.value)}
                        onFormNotValid={onFormNotValid}
                        onActionSuccess={onSubmitSEMBeneficiaireSuccess}
                        onActionFailure={onSubmitError}
                        debounceTimming={isOnboarding ? 1000 : undefined}
                    />
                    {
                        (isOnboarding || warning) && (
                            <SimpleButton 
                                label={warning ? "Ignorer" : "Passer"}
                                onClick={() => {
                                    if(isOnboarding){skipToNextStepKey()}
                                    else{
                                        onCancel && onCancel()
                                    }
                                }}
                            />
                        )
                    }
                    {
                        !isOnboarding && !warning && (
                            <SimpleButton 
                                label="Quitter"
                                onClick={onCancel}
                            />
                        )
                    }
                </div>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loginProps: state.authentication
});

const ConnectedSubscriptionSEMFormBeneficiaires = connect(mapStateToProps, null)(SubscriptionSEMFormBeneficiaires);
export { ConnectedSubscriptionSEMFormBeneficiaires as SubscriptionSEMFormBeneficiaires };

