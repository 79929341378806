import React, {useEffect, useState } from "react";
import { connect, useSelector} from "react-redux";  
import {Form } from "semantic-ui-react";
import { KycInformationsBody } from "../../../model/dto/body/KYC-informations-body";
import { KYCErrorMessageResponse, UserInfoResponse } from "../../../model/dto/response";
import { KYCDocumentError } from "../../../model/entities/kyc-document-error";
import { MangoPayEventReference } from "../../../model/entities/mangopay-event-reference";
import { StatutPieceJustificative } from "../../../model/entities/statut-piece-justificative";
import { UserPieceJustificative } from "../../../model/entities/user-piece-justificative";
import { AuthenticationState } from "../../../model/state/authentication-state";
import { userService } from "../../../services/authentication-redux-service";
import { referenceService } from "../../../services/reference-service";
import { ApplicationState } from "../../../store";
import { DropdownListFieldNew } from "../../generic/dropdown-list-field";
import { InputField } from "../../generic/input-field";
import { SubmitButton } from "../../generic/submit-button";
import { CheckBoxSimpleField } from "../../generic/checkbox-simple-field/checkbox-simple-field";
 

interface Props {
    loginProps?: AuthenticationState;
    userInfo?: UserInfoResponse;
    currentDocument?: UserPieceJustificative;
    onSubmitSuccess: () => void;
  }
type FieldData = {
  value?: any,
  name?: string,
  isValid?: boolean
};

type FormData = {
  Statut: FieldData,
  Raison: FieldData,
  StatutVillyz: FieldData,
  IdMangoPay: FieldData,
  IsPiecesJustifDone: FieldData
}

export function ChangeKYCInformations({
  userInfo,
  currentDocument,
  onSubmitSuccess
} : Props) {
  const [form, setForm] = useState<FormData>({
    Statut : { name : "Statut", value : currentDocument?.MangoPayEventCode, isValid: true},
    Raison : { name : "Raison", value : currentDocument?.ErrorMangoPay, isValid: true},
    StatutVillyz : { name : "StatutVillyz", value : currentDocument?.StatutPieceCode, isValid: true},
    IdMangoPay : { name : "IdMangoPay", value : currentDocument?.MangoPayLongId, isValid: true},
    IsPiecesJustifDone : {name : "IsPiecesJustifDone", value : userInfo?.IsPiecesJustifDone, isValid: true}
  })
  const [isFormError, setIsFormError] = useState(false)
  const [kycErrorMessageList, setKycErrorMessageList] = useState<KYCDocumentError[]>([])
  const [mangoPayEventList, setMangoPayEventList] = useState<MangoPayEventReference[]>([])
  const [statutPieceJustifList, setStatutPieceJustifList] = useState<StatutPieceJustificative[]>([])
  
  const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

  useEffect(() => {
    referenceService.getAllKycErrorMessage(loginProps?.oauth)
    .then(response => {
        return response.json() as Promise<KYCErrorMessageResponse>
    })
    .then(response => {
        if(response.IsTraitementOk) {
            setKycErrorMessageList(response.ErrorList!)
        }
    })
    
    referenceService.getAllMangoPayEvent()
    .then((response) => {
      if (response.IsTraitementOk) {
        setMangoPayEventList(response.MangoPayEventList!)
      }
    });
    
    referenceService.getAllStatutPieceJustificative()
    .then((response) => {
      if(response.IsTraitementOk) {
        setStatutPieceJustifList(response.StatutPieceJustifList!)
      }
    })
}, [])
  
const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean): void => {
    const fieldValue: string = e.target.value;
    setIsFormError(false)
    setForm({...form, [fieldName!]: {value: fieldValue, name: fieldName, isValid: isValid}})
}

  const body: KycInformationsBody = {
    Statut: form.Statut.value,
    Raison: form.Raison.value,
    IdMangoPay: form.IdMangoPay.value,
    StatutVillyz: form.StatutVillyz.value,
    UserId:  userInfo?.UserId,
    IsPiecesJustifDone : form.IsPiecesJustifDone.value
}
   
  return (
    <Form>
    <DropdownListFieldNew
        label="Statut"
        field={form.Statut}
        placeholder="Statut"
        datasource={mangoPayEventList.map((c) => ({
                    text: c.Code!,
                    value: c.Code!,
                    }))}
        onChange={(value) =>
                    handleInputChange(
                    { target: { value } } as any,
                    form.Statut.name
                    )
                }                        
    />
    <DropdownListFieldNew
        label="Raison"
        field={form.Raison}
        placeholder="Raison"
        datasource={kycErrorMessageList.map((c) => ({
                    text: c.Code!,
                    value: c.Code!,
                    }))}
        onChange={(value) =>
                    handleInputChange(
                    { target: { value } } as any,
                    form.Raison.name
                    )
                }                        
    />

    <DropdownListFieldNew
        label="Statut villyz"
        field={form.StatutVillyz}
        placeholder="StatutVillyz"
        datasource={statutPieceJustifList.map((c) => ({
                    text: c.Code!,
                    value: c.Code!,
                    }))}
        onChange={(value) =>
                    handleInputChange(
                    { target: { value } } as any,
                    form.StatutVillyz.name
                    )
                }                        
    />

    <Form.Field width={16}>
        <InputField 
            data={form.IdMangoPay}
            label="IdMangoPay"
            onChange={handleInputChange}
        />
    </Form.Field>
     <Form.Field width={16}>
          <CheckBoxSimpleField
              data={form.IsPiecesJustifDone}
              label="Je confirme que toutes les pièces concernant le KYC ont bien été transmises à Mangopay"
              onChange={(value: any, isValid: boolean) => {
                  handleInputChange(
                  { target: { value } } as any,
                  form.IsPiecesJustifDone.name,
                  isValid
                  );
              }}
          />
      </Form.Field>            
    <SubmitButton 
        data={body} 
        label="Valider"
        action={() => userService.updateKycInformationsByAdmin(body, loginProps?.oauth?.userId, currentDocument?.Id, loginProps?.oauth)}
        onActionSuccess={() => {
          onSubmitSuccess && onSubmitSuccess()
        }} 
    />
</Form>
  );
}
