import React, { useEffect, useMemo, useState } from 'react'
import './projet-list-new-V2.css'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import { AuthenticationState } from '../../store/authentication/types'
import { Profil } from '../../model/entities/profil'
import { trackPromise } from 'react-promise-tracker'
import { projetService } from '../../services/projet-service'
import { ProjetListItem } from '../../model/dto/projet-list-item'
import { publicService } from '../../services/public-service'
import { ProfilCode, ProjetDetailResponse } from '../../model/dto/response'
import Projet from '../../model/projet'
import { HeroActionBandeau } from './detail/parts/hero-action-bandeau/hero-action-bandeau'
import { Card, Container, Modal} from 'semantic-ui-react'
import { PourBientotScreen } from '../generic/c-est-pour-bientot'
import { LoadingSpinner } from '../generic/loading-spinner'
import { ProjetLine } from './projet-line'
import { SubmitButton } from '../generic/submit-button'
import { StatutProjet } from '../../model/entities/statut-projet'
import { FlowStep } from '../../model/entities/flow-step'
import SwitchCreateProjetWorflow from './switch-create-projet-worflow'
import { SimpleButton } from '../generic/simple-button'
import Agreement from '../pages/pages-components/agrement/agreement'
import RejoignezUneCommuEngagee from '../pages/pages-components/call-to-action/rejoignez-une-commu-engagee'

type Props = {}

export function ProjetListNewV2({}: Props) {
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)
    const [projetList, setProjetList] = useState<ProjetListItem[]>([])
    const [creationStep, setCreationStep] = useState<string | null>()
    const [isEdited, setIsEdited] = useState(false)
    const [newProjet, setNewProjet] = useState<Projet>()
    const [reload, setReload] = useState(true)
    const [isMecenatPaneClicked, setIsMecenatPaneClicked] = useState(loginProps?.oauth?.etablissementId !== undefined && loginProps?.oauth?.etablissementId.toString() !== "" && loginProps?.oauth?.profilCode !== Profil.VILLYZ_USER &&  loginProps?.oauth?.profilCode !== Profil.PORTEUR_PROJET? true : false)


    useEffect(() => {
        if(reload) {
            if(loginProps?.oauth?.profilCode === Profil.PORTEUR_PROJET) {
                trackPromise(projetService.getProjetList(loginProps?.oauth, isMecenatPaneClicked)
                .then(data => {
                    if (data) {
                        setProjetList(data.ProjetList!)
                    }
                }));
            } else if(loginProps?.oauth?.profilCode === Profil.VILLYZ_USER) {
                trackPromise(projetService.getProjetAdmin(loginProps?.oauth, isMecenatPaneClicked)
                .then(data => {
                    if (data) {
                        setProjetList(data.ProjetList!)
                    }
                }));
            }
            else {
                trackPromise(publicService.getProjetList(isMecenatPaneClicked)
                .then(response => {
                    setProjetList(response.ProjetList!)
                }));
            }
            setReload(false)
        }
    }, [reload])

    const stepTitle = useMemo(() => {
        if(!creationStep) {
            return "Type de collecte"
        }
        if(creationStep === FlowStep.INFORMATIONS_GENERALES) {
            return "Informations générales"
        }
            
        if(creationStep === FlowStep.DESCRIPTION) {
            return "Description du projet"
        }
            
        if(creationStep === FlowStep.FINANCEMENT_GLOBAL) {
            return "Financement du projet"
        }

        if(creationStep === FlowStep.FINANCEMENT_PARTICIPATIF) {
            return "Financement participatif"
        }

        if(creationStep === FlowStep.PERIODE_DE_COLLECTE) {
            return "Période de collecte"
        }

        if(creationStep === FlowStep.FINALISATION) {
            return "Finalisation"
        }
            
       return ""
    }, [creationStep])

    const onNewProjetClick = (response: ProjetDetailResponse) => {
        setIsEdited(true)
        setNewProjet(response.Projet)
    }

    const updateStep = (stepKey: string) => {
        setCreationStep(stepKey)
    }

    const isCardClickable = (projet: ProjetListItem) => {
        if(projet.StatutProjetCode === StatutProjet.A_VENIR || projet.StatutDonProjetCode === StatutProjet.A_VENIR){
            if(loginProps?.user?.ProfilCode === ProfilCode.PORTEUR_PROJET && projet.EtablissementId === loginProps?.user?.Etablissement?.Id){
                return true
            }
            else if(loginProps?.oauth?.profilCode === ProfilCode.VILLYZ){
                return true
            }
            return false
        }
        return true
    }

    const handlePaneChange = () => {
        setIsMecenatPaneClicked(!isMecenatPaneClicked)
        setReload(true)
    }

    return (
        <>
            {loginProps?.oauth?.profilCode === ProfilCode.PORTEUR_PROJET ?
                <HeroActionBandeau 
                    classPage="projets"
                    title="Engagez vos citoyens"
                 />
            :
            loginProps?.oauth?.profilCode === ProfilCode.INVESTISSEUR && loginProps?.user?.Etablissement !== null ?
                <HeroActionBandeau 
                    classPage="projets"
                    title="Engagez votre entreprise"
                />
                : 
                <HeroActionBandeau 
                    classPage="projets"
                    title="Investissez dans vos valeurs"
                />
            } 
            <Container>
                {((loginProps?.user?.ProfilCode === ProfilCode.INVESTISSEUR && loginProps.user?.Etablissement === null )|| !loginProps?.oauth) &&
                <div>
                    <LoadingSpinner>
                        <Card.Group itemsPerRow={3} doubling stackable>
                            {projetList.map(projetItem => 
                                <ProjetLine 
                                    key={projetItem.Id} 
                                    projet={projetItem} 
                                    isCardClickable={isCardClickable(projetItem)}
                                    reload={()=> setReload(true)} 
                                    isDon={false}
                                />
                            )}
                        </Card.Group>
                    </LoadingSpinner>
                </div>
                }
                {(loginProps?.user?.ProfilCode === ProfilCode.INVESTISSEUR && loginProps.user?.Etablissement) &&
                <div>
                    <LoadingSpinner>
                        <Card.Group itemsPerRow={3} doubling stackable>
                            {projetList.map(projetItem => 
                                <ProjetLine 
                                    key={projetItem.Id} 
                                    projet={projetItem} 
                                    isCardClickable={isCardClickable(projetItem)}
                                    reload={()=> setReload(true)} 
                                    isDon={true}
                                />
                            )}
                        </Card.Group>
                    </LoadingSpinner>
                </div>
                }
                {/* Cas d'affichage des boutons uniquement pour les porteurs ou l'admin */}
                {(loginProps?.user?.ProfilCode === ProfilCode.PORTEUR_PROJET || loginProps?.oauth?.profilCode === Profil.VILLYZ_USER) && 
                <div>
                    <div className='container-btn'>
                        <SimpleButton 
                        label='Investissement citoyen'
                        inverted={isMecenatPaneClicked ? true : false}
                        onClick={handlePaneChange}
                        className='margin-to-button-at-right'
                        stretchOnDesktop
                        ralewayAnd16
                        />
                        <SimpleButton 
                        label="Mécénat d'entreprise"
                        inverted={isMecenatPaneClicked ? false : true}
                        onClick={handlePaneChange}
                        stretchOnDesktop
                        ralewayAnd16
                        />
                    </div>
                    <div>
                        {!isMecenatPaneClicked && 
                        <LoadingSpinner>
                            {projetList.length > 0 ?
                                <Card.Group itemsPerRow={3} doubling stackable>
                                    {projetList.map(projetItem => 
                                        <ProjetLine 
                                            key={projetItem.Id} 
                                            projet={projetItem} 
                                            isCardClickable={isCardClickable(projetItem)}
                                            reload={()=> setReload(true)} 
                                            isDon={isMecenatPaneClicked}
                                        />
                                    )}
                                </Card.Group>
                            : 
                                <PourBientotScreen title="Publiez votre 1er projet !"/>
                            }
                        </LoadingSpinner>
                        }
                        {isMecenatPaneClicked && 
                        <LoadingSpinner>
                        {projetList.length > 0 ? (
                            <Card.Group itemsPerRow={3} doubling stackable>
                                {projetList.map((projetItem) => (
                                    <ProjetLine
                                        key={projetItem.Id}
                                        projet={projetItem}
                                        isCardClickable={isCardClickable(projetItem)}
                                        isDon={isMecenatPaneClicked}
                                        reload={() => setReload(true)}
                                    />
                                    ))}
                            </Card.Group>
                            ) : (
                            <PourBientotScreen title="Publiez votre 1er projet !" /> 
                        )}
                        </LoadingSpinner>
                        }
                    </div>
                </div>
                }
              
              <div style={{margin: "20px 0"}}>
                    {loginProps && loginProps.oauth?.profilCode === ProfilCode.PORTEUR_PROJET && (
                    <div style={{textAlign : "center"}}>
                        <SubmitButton 
                            label="Créer un nouveau projet"
                            action={() => projetService.createProjet(loginProps?.oauth)}
                            onActionSuccess={onNewProjetClick}
                            
                        />
                        <Modal closeOnDocumentClick={false} css={{overflowX: "hidden"}} onClose={() => setIsEdited(false)} open={isEdited}>
                            <Modal.Header>{stepTitle}</Modal.Header>
                            <Modal.Content>
                            <Modal.Description>
                                <SwitchCreateProjetWorflow 
                                    newProjet={newProjet!}
                                    onNextStep={updateStep}
                                    loginProps={loginProps}
                                />
                            </Modal.Description>
                            </Modal.Content>
                        </Modal>
                    </div>
                    )}
                </div>

                {/*========================  LEGAL BANDEAU SECTION =====================*/}
                <div style={{marginTop : '14vh'}}>
                    <Agreement/>
                </div>
                <div style={{marginTop:'100px', marginBottom: '80px'}}>
                    <RejoignezUneCommuEngagee width={88} isButtonEmpty/>
                </div>            

                
            </Container>
        </>
    )
}