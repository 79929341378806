import React, { useEffect, useState } from "react";
import { NumberFormatBaseProps, PatternFormat } from "react-number-format";

type FieldData = {
    value?: any;
    name?: string;
    isValid?: boolean;
};

type Props = {
    data: FieldData;
    label: string;
    error?: string;
    submitted?: boolean;
    className?: string;
    mask?: string | Array<string>;
    formatModel: string;
    allowEmptyFormatting?: boolean;
    type: NumberFormatBaseProps["type"];
    value?: string;
    autoComplete?: string;
    onChange: (date: string) => void;
    validateField?: (newValue: string ) => boolean;
};

export default function DateMaskFieldNewV2({
    data,
    label,
    error,
    className,
    mask,
    formatModel,
    allowEmptyFormatting,
    submitted,
    type,
    value,
    onChange,
    validateField
}: Props) {
   
    const [isValid, setIsValid] = useState(true);


    useEffect(() => {
    if (!submitted) return;

    const isValid = value && validateField ? validateField(value) : false;
    setIsValid(isValid);

    }, [submitted]);

    const onInputChange = (value: string) => {
        onChange(value)
        setIsValid(true)
    }

    return (
        <>
            <label htmlFor={data.name}>{label}</label>
            <div>
                <PatternFormat
                    name={data.name}
                    style={{ height: "auto" }}
                    className={className}
                    onValueChange={(values, sourceInfo) =>
                    { 
                        onInputChange(values.value)
                    }
                    }
                    mask={mask}
                    format={formatModel}
                    allowEmptyFormatting={allowEmptyFormatting}
                    valueIsNumericString={true}
                    type={type}
                    value={value}
                />

                {!isValid && (
                    <div
                        style={{
                            color: "#F03E3E",
                            fontSize: "10px",
                            marginTop: "4px",
                        }}
                    >
                        {error}
                    </div>
                )}
            </div>
        </>
    );
}
