import React, { useState } from "react";
import { NumberFormatBaseProps, PatternFormat } from "react-number-format";
import {isValid, parseISO} from 'date-fns'

type FieldData = {
    value?: any;
    name?: string;
    isValid?: boolean;
};

type Props = {
    data: FieldData;
    label: string;
    error?: string;
    submitted?: boolean;
    className?: string;
    mask?: string | Array<string>;
    formatModel: string;
    allowEmptyFormatting?: boolean;
    type: NumberFormatBaseProps["type"];
    onError?: boolean;
    value?: string;
    autoComplete?: string;
    onChange?: (date: Date | null, isValid?: boolean) => void;
    validateField?: (newValue: any) => boolean;
};
type ReverseDateString = (date: string) => string;

export default function DateMaskFieldNew({
    data,
    label,
    error,
    className,
    mask,
    formatModel,
    allowEmptyFormatting,
    submitted,
    type,
    onError,
    value,
    autoComplete,
    onChange,
    validateField,
}: Props) {
    const [isValidDate, setIsValidDate] = useState(true);

    //tranformation date logic
    const reverseDateString: ReverseDateString = (date: string) => {
        date = date.replace(/\s/g, "");
        return date.split("/").reverse().join("-");
    };

    const isValidateDate = (date: string) => {
        date = date.replace(/\s/g, "").split("/").reverse().join("-")
        return isValid(parseISO(date))
    }

    const onInputChange = (value: string) => {

        const reversedStringDate= reverseDateString(value)
        const isValidField = validateField?.(reversedStringDate) ?? true;

        let dateValue
        if(isValidateDate(reversedStringDate)){
            dateValue = new Date(reversedStringDate)
        }else {
            dateValue = null
        }
        setIsValidDate(isValidateDate(reversedStringDate))

        onChange?.(dateValue, isValidField);

    };
   

    return (
        <>
            <label htmlFor={data.name}>{label}</label>
            <div>
                <PatternFormat
                    name={data.name}
                    style={{ height: "auto" }}
                    className={className}
                    onValueChange={(values, sourceInfo) =>
                    { 
                        onInputChange(values.formattedValue)
                    }
                    }
                    mask={mask}
                    format={formatModel}
                    allowEmptyFormatting={allowEmptyFormatting}
                    valueIsNumericString={true}
                    type={type}
                    value={value ?? ''}
                    //autoComplete={autoComplete}
                />

                {onError && (
                    <div
                        css={{
                            color: "#F03E3E",
                            fontSize: "10px",
                            marginTop: "4px",
                        }}
                    >
                        {error}
                    </div>
                )}
            </div>
        </>
    );
}
