import React from 'react'
import { Grid, Segment } from 'semantic-ui-react'
import ClipboardCopier from '../../generic/clipboard-copier/clipboard-copier'
import './virement-classique-submited-pane.css'
import { LinkButton } from '../../generic/link-button'

interface Props {
    iban?:string,
    montantVirement?: number,
    montantInitial?: string | number,
    bic?:string,
    delaiReservationVirement?: string,
    montantDisponibleVillyz?: number,
    isDon?: boolean,
    onClose : () => void
}

export default function VirementClassiqueSubmitedPane({iban, montantVirement, montantInitial, bic, isDon, delaiReservationVirement, montantDisponibleVillyz, onClose} : Props) {
    const beneficiaire = 'MANGOPAY SA'

  return (
    <Grid stackable stretched padded className='virement-resume'>
        <Grid.Row className='virement-resume-row'>
            <Grid.Column className='virement-resume-column'>
                <Segment >
                    <div className='flex-column'>
                        <p style={{fontWeight: 'bold', fontSize: '16px', marginBottom: '15px'}}>Coordonnées bancaires à enregistrer auprès de votre banque.</p>
                        <div style={{display : 'flex', alignItems:'center'}}className='resume-info-container '>
                            <div className='image-icon'>
                                <img src='/img/icones/beneficiaire.svg' alt='icone de bénéficiaire'/>
                            </div>
                            <div style={{display:'flex'}}>
                                <p className='resume-info-paragraph'>Bénéficiaire : {beneficiaire}</p>
                                <div style={{alignSelf: 'baseline'}}>
                                    <ClipboardCopier value={beneficiaire}/>
                                </div>
                            </div>
                        </div>
                        <div className='resume-info-container align-center'>
                            <div className='image-icon'>
                                <img src='/img/icones/iban.svg' alt="icone de l'iban"/>
                            </div>
                            <div style={{display:'flex'}}>
                                <p className='resume-info-paragraph'>IBAN : {iban}</p>
                                <div style={{alignSelf: 'baseline'}}>
                                    <ClipboardCopier value={iban}/>
                                </div>
                            </div>
                        </div>
                        <div className='resume-info-container align-center'>
                            <div className='image-icon'>
                                <img src='/img/icones/iban.svg' alt="icone de l'iban"/>
                            </div>
                            <div style={{display:'flex'}}>
                                <p className='resume-info-paragraph'>BIC : {bic}</p>
                                <div style={{alignSelf: 'baseline'}}>
                                    <ClipboardCopier value={bic}/>
                                </div>
                            </div>
                        </div>
                        <div  className='resume-info-container'>
                            <div className='image-icon'>
                                <img src='/img/icones/montant.svg' alt="icone de banque"/>
                            </div>
                            <div className='flex-column'>
                                <div style={{display:'flex'}}>
                                    <p className='resume-info-paragraph'>Montant à transférer : {montantVirement} €</p>
                                    <div style={{alignSelf: 'baseline'}}>
                                        <ClipboardCopier value={montantVirement}/>
                                    </div>
                                </div>
                                {!isDon && montantDisponibleVillyz! > 0 &&
                                    <p><i>Ce montant correspond à votre investissement de {montantInitial} € diminué des sommes disponibles sur votre compte Villyz</i></p>
                                }
                            </div>
                        </div>
                        <div className='resume-info-container'>
                            <div className='image-icon'>
                            <img src='/img/icones/reference2.svg' alt="icone pour la référence"/>
                            </div>
                            <div style={{display:'flex'}}>
                                <p className='resume-info-paragraph'>Référence : Vous n'avez pas besoin de préciser de référence car l'IBAN vous est propre. Toutefois, pour mieux identifier votre virement sur votre relevé nous vous conseillons de renseigner une référence personnelle.</p>
                            </div>
                        </div>
                        <p style={{marginTop:'30px'}}>
                            Ces informations vous ont aussi été transmises par email, votre {!isDon ? 'investissement': 'don'} sera définitivement validé une fois les fonds reçus.                
                        </p>
                        <p>
                            Nous vous recommandons d'effectuer votre virement le plus tôt possible, votre place étant réservée pendant {delaiReservationVirement} jours.                
                        </p>
                        <div style={{marginTop:'20px', alignSelf:'end'}}>
                            <LinkButton label="Quitter" onClick={onClose}/>                          
                        </div>
                    </div>
                </Segment>
            </Grid.Column>
        </Grid.Row>
    </Grid>  
  )
}
