import dayjs from "dayjs"
import React, { useState } from "react"
import { Button, Modal } from "semantic-ui-react"
import { PaginatedTable } from "../../generic/paginated-table/paginated-table"
import { ChangeHistoRemboursementInformations } from "./change-histo-remboursement"
import { InvestissementHistoryResponse, RemboursementHistoryResponse, RetraitHistoryResponse, UserInfoResponse } from "../../../model/dto/response"
import { ChangeKYCInformations } from "./change-kyc-informations"
import { UserPieceJustificative } from "../../../model/entities/user-piece-justificative"
import { ChangeHistoInvestInformations } from "./change-histo-investissements"
import { ChangeHistoRetrait } from "./change-histo-retrait"
import { EtablissementPieceJustificative } from "../../../model/entities/etablissement-piece-justificative"
import { SubmitButton } from "../../generic/submit-button"
import { GenerateVirementClassique } from "./generate-virement-classique"
import { SimpleButton } from "../../generic/simple-button"
import { investmentService } from "../../../services/investment-service"
import { AuthenticationState } from "../../../model/state/authentication-state"
import { useSelector } from "react-redux"
import { ApplicationState } from "../../../store"
import { StatutInvestissement } from "../../../model/entities/statut-investissement"
import { ModePaiement } from "../../../model/entities/mode-paiement"

type Props = {
    userInfo?: UserInfoResponse,
    userPieceJustifList: UserPieceJustificative[] | EtablissementPieceJustificative[],
    investHistoryList: InvestissementHistoryResponse[],
    remboursementHistoryList: RemboursementHistoryResponse[],
    retraitHistoryList: RetraitHistoryResponse[],
    askReload: (value: boolean) => void
} 

export function DetailOperation({
    userInfo,
    userPieceJustifList,
    investHistoryList,
    remboursementHistoryList,
    retraitHistoryList,
    askReload
}: Props) {
    const [isEdited, setIsEdited] = useState<boolean>(false)
    const [isEditedInvest, setIsEditedInvest] = useState<boolean>(false)
    const [isEditedRemboursement, setIsEditedRemboursement] = useState<boolean>(false)
    const [isEditedRetrait, setIsEditedRetrait] = useState<boolean>(false)
    const [editedElementInvest, setEditedElementInvest] = useState<InvestissementHistoryResponse | undefined>()
    const [editedElementRemboursement, setEditedElementRemboursement] = useState<RemboursementHistoryResponse | undefined>()
    const [editedElementRetrait, setEditedElementRetrait] = useState<RetraitHistoryResponse | undefined>()
    const [pieceEdited, setPieceEdited] = useState<UserPieceJustificative | undefined>()
    const [isGenerateVirementClassique, setGenerateVirementClassique] = useState<boolean>(false)
    const [isConfirmationRemboursementOpen, setIsConfirmationRemboursementOpen] = useState<boolean>(false)

    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)
    
    const isDateLowerThanNumberOfDays = (initialDate: Date, numberOfDays: number): boolean => {
        const investDate = new Date(initialDate)
        const actualDate = new Date(Date.now())
        const deadline = new Date(actualDate.setDate(actualDate.getDate() - numberOfDays))

        return investDate >= deadline
    }
    const displayConditionForReservedInfo =  (element: InvestissementHistoryResponse) => {
        if(element && element.StatutInvestissement?.Code === StatutInvestissement.INITIE && element.ModePaiementCode === ModePaiement.VIREMENT_CLASSIQUE && isDateLowerThanNumberOfDays(element.DateInvestissement!, 10)){
            return `${element.StatutInvestissement?.Libelle} - Réservé `
        }else{
            return element.StatutInvestissement?.Libelle
        }
    }

    const displayCancelVirementReservation = (element: InvestissementHistoryResponse) => {
        return element && element.StatutInvestissement?.Code === StatutInvestissement.INITIE && element.ModePaiementCode === ModePaiement.VIREMENT_CLASSIQUE
    }

    return (
        <>
            <h2>Informations Kyc</h2>
            <PaginatedTable 
                elements={userPieceJustifList!}
                elementsPerPage={4}
                headers={
                    [
                        'Date',
                        'Statut MangoPay', 
                        'Raison erreur',
                        'Id MangoPay',
                        'Statut Villyz',
                        'Action',  
                    ]
                }
                columnSelector={(element: UserPieceJustificative | EtablissementPieceJustificative) => { 
                    return {
                        values: [
                            dayjs(element?.DateCreated).format("DD/MM/YYYY"),
                            element?.MangoPayEventCode,
                            element?.ErrorMangoPay,
                            element?.MangoPayLongId,
                            element?.StatutPieceCode,
                            <div>
                                <Button 
                                circular  
                                icon='pencil'
                                onClick={() => {
                                    setIsEdited(true)
                                    setPieceEdited(element)
                                }}
                                />
                            </div>
                        ]
                    }
                }}
                />
            <Modal 
            closeOnDimmerClick
            onClose={() => setIsEdited(false)}
            open={isEdited}
            >
                <Modal.Header>Informations KYC</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                    <ChangeKYCInformations 
                        userInfo={userInfo}
                        currentDocument={pieceEdited}
                        onSubmitSuccess={() => {
                            setIsEdited(false)
                            askReload(true)
                        }}
                    />
                    </Modal.Description>
                </Modal.Content>
            </Modal>
            <div css={{display: 'flex', justifyContent: 'space-between', marginTop: '32px'}}>
                <h2>Informations Investissement</h2>
                <SimpleButton 
                    label="Déclarer un virement classique"
                    onClick={() => {
                        setGenerateVirementClassique(true)
                    }}
                />
            </div>
            <Modal
                open={isGenerateVirementClassique}
            >
                <Modal.Header>Déclarer un virement</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <GenerateVirementClassique 
                            userId={userInfo?.UserId}
                            investHistoryList={investHistoryList}
                            onCloseForm={() => {
                                setGenerateVirementClassique(false);
                                askReload(true)
                            }}
                        />
                    </Modal.Description>
                </Modal.Content>
            </Modal>
            <PaginatedTable 
                elements={investHistoryList}
                elementsPerPage={4}
                headers={
                    [
                        'Date Investissement',
                        'Projet', 
                        'Montant',
                        'Statut investissement',
                        'Statut MangoPay',
                        'Id MangoPay',
                        'Statut TrueLayer',
                        'Id TrueLayer',
                        'Action', 
                    ]
                }
                columnSelector={(element: InvestissementHistoryResponse) => { 
                    return {
                        values: [
                            dayjs(element?.DateInvestissement).format("DD/MM/YYYY"),
                            element?.Titre,
                            element?.MontantInvesti,
                            displayConditionForReservedInfo(element),
                            element?.MangoPayStatus,
                            element?.MangoPayId,
                            element.TrueLayerTrueLayerPaymentStatus,
                            element?.TrueLayerId,
                            <div css={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}>
                                <Button 
                                    circular  
                                    icon='pencil' 
                                    onClick={() => {
                                        setIsEditedInvest(true)
                                        setEditedElementInvest(element)
                                    }}
                                />
                                <Button 
                                    circular  
                                    icon='arrow alternate circle left' 
                                    onClick={() => {
                                        setIsConfirmationRemboursementOpen(true)
                                        setEditedElementInvest(element)
                                    }}
                                />
                            </div>
                        ]
                    }
                }}
            />
            <Modal 
                closeOnDimmerClick
                onClose={() => setIsEditedInvest(false)}
                open={isEditedInvest}
                >
                <Modal.Header>Informations Investissement</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <ChangeHistoInvestInformations 
                            userId={userInfo?.UserId}
                            currentHistoInvest={editedElementInvest}
                            onSubmitSuccess={() => {
                            setIsEditedInvest(false)
                            askReload(true)
                            }}
                        />
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        {editedElementInvest && displayCancelVirementReservation(editedElementInvest) ? 
            <Modal 
            closeOnDimmerClick
            onClose={() => setIsConfirmationRemboursementOpen(false)}
            open={isConfirmationRemboursementOpen}
        >
            <Modal.Header>Confirmation de l'annulation de la réservation du virement</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <p>Souhaitez-vous confirmer l'annulation de cette opération ?</p>
                    <SubmitButton 
                        label="Confirmer"
                        action={() => investmentService.cancelInvestementByVirementClassiqueReservationByAdmin(loginProps.oauth, editedElementInvest?.Id!,)}
                        onActionSuccess={() => {
                            setIsConfirmationRemboursementOpen(false)
                            askReload(true)
                        }}
                    />
                </Modal.Description>
            </Modal.Content>
        </Modal>
        :
        <Modal 
                closeOnDimmerClick
                onClose={() => setIsConfirmationRemboursementOpen(false)}
                open={isConfirmationRemboursementOpen}
            >
                <Modal.Header>Confirmation de remboursement</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <p>Souhaitez-vous confirmer le remboursement de cette opération ?</p>
                        <SubmitButton 
                            label="Confirmer"
                            action={() => investmentService.askForRemboursementAdmin(editedElementInvest?.Id!, loginProps.oauth)}
                            onActionSuccess={() => {
                                setIsConfirmationRemboursementOpen(false)
                                askReload(true)
                            }}
                        />
                    </Modal.Description>
                </Modal.Content>
            </Modal>
            }

            <h2>Informations Remboursement</h2>
            <PaginatedTable 
            elements={remboursementHistoryList}
            elementsPerPage={4}
            headers={
                [
                    'Date opération',
                    'Projet', 
                    'Montant',
                    'Statut',
                    'Id MangoPay',
                    'Action',  
                ]
            }
            columnSelector={(element: RemboursementHistoryResponse) => { 
                return {
                    values: [
                        dayjs(element?.DateCreation).format("DD/MM/YYYY"),
                        element?.Titre,
                        element?.Montant,
                        element?.Statut,
                        element?.MangoPayId,
                        <div>
                            <Button 
                            circular  
                            icon='pencil' 
                            onClick={() => {
                                setIsEditedRemboursement(true)
                                setEditedElementRemboursement(element)
                            }}
                            />
                        </div>
                    ]
                }
            }}
            />
            <Modal 
                closeOnDimmerClick
                onClose={() => setIsEditedRemboursement(false)}
                open={isEditedRemboursement}
            >
                <Modal.Header>Informations Remboursement</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <ChangeHistoRemboursementInformations 
                            userId={userInfo?.UserId}
                            currentHistoRemboursement={editedElementRemboursement}
                            onSubmitSuccess={() => {
                                setIsEditedRemboursement(false)
                                askReload(true)
                            }}
                        />
                    </Modal.Description>
                </Modal.Content>
            </Modal>
            <h2>Informations Retrait</h2>
            <PaginatedTable 
                elements={retraitHistoryList!}
                elementsPerPage={4}
                headers={
                    [
                        'Date opération', 
                        'Montant',
                        'Statut',
                        'Id MangoPay', 
                        'Action',  
                    ]
                }
                columnSelector={(element: RetraitHistoryResponse) => { 
                    return {
                        values: [
                            dayjs(element?.DateDemande).format("DD/MM/YYYY"),
                            element?.Montant,
                            element?.Statut,
                            element?.MangoPayId,
                            <div>
                                <Button 
                                circular  
                                icon='pencil' 
                                onClick={() => {
                                    setIsEditedRetrait(true)
                                    setEditedElementRetrait(element)
                                }}
                                />
                            </div>
                        ]
                    }
                }}
            />
            <Modal 
            closeOnDimmerClick
            onClose={() => setIsEditedRetrait(false)}
            open={isEditedRetrait}
            >
                <Modal.Header>Informations Retrait</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <ChangeHistoRetrait 
                            userId={userInfo?.UserId}
                            currentHistoRetrait={editedElementRetrait}
                            onSubmitSuccess={() => {
                            askReload(true)
                            setIsEditedRetrait(false)
                            }}
                        />
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </>
    )
}