import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { referenceService } from "../../services/reference-service";
import {
  MultipleFileRegistrationResponse,
  OnboardingCitoyenResponse,
  OnboardingCollectiviteResponse,
  OnboardingSEMResponse,
  ProfilCode,
  UserInfoResponse,
} from "../../model/dto/response";
import { SimpleButton } from "../generic/simple-button";
import { ApplicationState } from "../../store";
import * as isLeapYear from "dayjs/plugin/isLeapYear"; // import plugin
import "dayjs/locale/fr-ch"; // import locale
import { Container, Grid, Header, Icon, Modal, Segment, Table } from "semantic-ui-react";
import { EditableBlock } from "../generic/editable-block/editable-block";
import { ProfilCompletionHeader } from "./profil-completion-header";
import { SubscriptionAdresseRepresentant } from "../login/subscription-adresse-representant";
import { SubscriptionAdresseEtablissement } from "../login/subscription-adresse-etablissement";
import { PieceJustifLine } from "./piece-justif-line";
import { TypePieceByProfil } from "../../model/entities/type-piece-by-profil";
import { userService } from "../../services/authentication-redux-service";
import { SubscriptionIdentiteRepresentantForm } from "../login/subscription-identite-representant-form";
import { EtablissementPieceJustificative } from "../../model/entities/etablissement-piece-justificative";
import { LoadingSpinner } from "../generic/loading-spinner";
import { OnboardingStatus } from "../../model/entities/onboarding-status";
import { trackPromise } from "react-promise-tracker";
import { Pays } from "../../model/entities/pays";
import {LogoUploadForm} from "../projet/forms/logo-upload-form";
import { publicService } from "../../services/public-service";
import { Beneficiaire } from "../../model/entities/beneficiaire";
import { SubscriptionSEMFormBeneficiaires } from "../login/subscription-sem-form-beneficiaires";
import { SubmitButton } from "../generic/submit-button";
import { CategorieEtablissement } from "../../model/entities/categorie-etablissement";
import { TypeInvestisseur } from "../../model/entities/types-investisseur";
import { CapaciteInvestissementForm } from "../ecsp/capacite-investissement-form";
import { ExperienceInvestissementForm } from "../ecsp/experience-investissement-form";
import { OnboardingInvestisseurMoralPorteurProjetDocumentListForm } from "../login/onboarding-investisseur-moral-porteur-projet-document-list-form";
import { TypeInvestisseurMorale } from "../../model/entities/type-investisseur-morale";
import { AuthenticationAction } from "../../actions/authentication-actions";
import { CHANGE_IDENTITY } from "../../actions/authentication-actions";
import { AuthenticationState } from "../../model/state/authentication-state";
import { StatutPieceJustificative } from "../../model/entities/statut-piece-justificative";
import { ResendValidationEmailBody } from "../../model/dto/body/resend-validation-email-body";

var dayjs = require("dayjs");
dayjs.extend(isLeapYear); // use plugin
dayjs.locale("fr-ch"); // use locale

interface Props {
  userId?: number
}

export function ProfilPersonneMoraleDetail({userId}: Props) {
  const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)
  const dispatch = useDispatch()
  
  const [userInfo, setUserInfo] = useState<UserInfoResponse>()
  const [typePiecesJustifList, setTypePiecesJustifList] = useState<TypePieceByProfil[]>([])
  const [userPieceJustifList, setUserPieceJustifList] = useState<EtablissementPieceJustificative[]>([])
  const [paysList, setPaysList] = useState<Pays[]>([])
  const [isLogoDispo, setIsLogoDispo] = useState(false)
  const [logoUrl, setLogoUrl] = useState("")
  const [reload, setReload] = useState(true)
  const [beneficiairesList, setBeneficiairesList] = useState<Beneficiaire[]>([])
  const [isValidateModalOpen, setIsValidateModalOpen] = useState(false)
  const [validateErrorMessage, setValidateErrorMessage] = useState(false)
  const [sendMailValidation, setSendMailValidation] = useState(false)
  const [openPopUpMessage, setOpenPopUpMessage] = useState(false)

  useEffect(() => {
    if(reload) {
      trackPromise(userService.getUserInfo(loginProps?.oauth?.userId, loginProps?.oauth)
      .then((response) => {
        setUserInfo(response)
      }), "main");
  
      trackPromise(userService.getTypePieceForUser(loginProps?.oauth?.userId, loginProps?.oauth)
      .then((response) => {
        if (response.IsTraitementOk) {
          setTypePiecesJustifList(response.TypePieceList!)
        }
      }), "main");
  
      trackPromise(userService.getUserPiecesJustifList(loginProps?.oauth?.userId, loginProps?.oauth)
      .then((response) => {
        if (response.IsTraitementOk) {
          setUserPieceJustifList(response.EtablissementPieceJustifList!)
        }
      }), "main");
  
      trackPromise(referenceService.getAllPays()
      .then((response) => {
        if (response.IsTraitementOk) {
          setPaysList(response.PaysList!)
        }
      }), "main");
          
      trackPromise(publicService.checkLogoExistance(loginProps?.oauth?.etablissementId!)
      .then(
        (response) => {
          setIsLogoDispo(response.Exist)
          if(response.Exist) {
            setLogoUrl(`${process.env.REACT_APP_BASE_URL}/public/get-logo/${loginProps?.oauth?.etablissementId!}`)
      }          
      }));

      trackPromise(userService.getBeneficiaires(loginProps?.oauth?.userId, loginProps?.oauth)
        .then((response) => {
          setBeneficiairesList(response?.BeneficiairesList ?? [])
      }), "main");
    
      setReload(false)
    }
  }, [reload])

  const daysLeft = (date: Date) => {
    let difference = new Date(date.getFullYear() + 2, date.getMonth(), date.getDate() - 1).getTime() - Date.now();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays;
  }

  const body : ResendValidationEmailBody = {
    IsClassique : true
  }

  const adresseMail = () => {
    return (
      <div>
        <div className="form-group">
          <p>{userInfo?.Mail}</p>
          <p>{!userInfo?.IsMailValid && "non"} validée</p>
        </div>
          {!userInfo?.IsMailValid && !sendMailValidation && (
            <div>
              <SimpleButton label="Renvoyer un email de validation" onClick={() => 
                userService.authenticatedAction(loginProps?.oauth).resendValidationEmail(body, loginProps?.user?.UserId).then(
                  (response) => {
                    if (response.IsTraitementOk) {
                      setSendMailValidation(true) 
                      setOpenPopUpMessage(true)
                    }
                  }
                )
              } />
            </div>
          )}
          {openPopUpMessage && sendMailValidation? 
            <div 
              css={{
                position:"relative", 
                backgroundColor: '#DFF0D8', 
                color: '#3C763D', 
                fontWeight: 'bold', 
                borderRadius: '4px',
                padding: '1rem 1.45rem',
                display:"flex", 
                flexDirection:"row", 
                alignItems:"center",
              }}
            >
              <div 
                onClick={() => {
                  setOpenPopUpMessage(false)
                  setSendMailValidation(false)
                }}
                css={{
                  position: "absolute", 
                  right:"3px", 
                  top:"2px", 
                  cursor:"pointer"
                }}>
                  <Icon name="times" css={{color: '#3C763D !important', "&:hover": {color: "#71797E !important"}}} size="small"></Icon>
              </div>

              <div css={{flexBasis: "4%", alignSelf:"center", marginLeft:"-8px"}}>
                <Icon name='check' size='large' css={{color: '#3C763D'}}/>
              </div>
                
              <div css={{flexBasis: "96%"}}>
                <p css={{color: '#3C763D', fontWeight:"bold"}}>
                  Un nouvel email vient de vous être envoyé (pensez à vérifier vos spams)
                </p>
              </div>
            </div>
            :
            <div></div>
          }
      </div>
    );
  }

  const identiteRepresentant = () => {
    return (
      <div>
        <div>Prénom : {userInfo?.Prenom}</div>
        <div>Nom : {userInfo?.Nom}</div>
        {(loginProps.user?.Etablissement?.CategorieCode === CategorieEtablissement.COLLECTIVITE || loginProps.user?.Etablissement?.TypeInvestisseurMoraleCode === TypeInvestisseurMorale.COLLECTIVITE) && <div>Qualité : {userInfo?.QualiteRepresentant}</div>}
        {userInfo?.Civilite? (
          <div>Civilité : {userInfo.Civilite}</div>
        ) : <div></div>}
        <div>
          Date de naissance :{" "}
          {userInfo?.DateNaissance && userInfo?.DateNaissance !== null && dayjs(userInfo?.DateNaissance).format("DD/MM/YYYY")}
        </div>
        <div>Nationalité : {paysList ? paysList.find(p => p.Code === userInfo?.Nationalite)?.Nom : ""}</div>
      </div>
    );
  }

  const identiteSEM = ()=>{
    return (
      <div>
        <div>Nom de la société : {userInfo?.Etablissement?.Designation}</div>
        <div>Numéro SIREN : {userInfo?.Etablissement?.CodeSiren}</div>
      </div>
    )
  }
  
  const etablissement = () => {
    return (
      <div>
        {(loginProps?.user?.Etablissement && loginProps?.user?.Etablissement?.CategorieCode === CategorieEtablissement.COLLECTIVITE) || (!loginProps?.user?.Etablissement && loginProps.user?.Etablissement?.TypeInvestisseurMoraleCode === TypeInvestisseur.COLLECTIVITE) &&
          <div>
            Type de collectivité :{" "}
            {userInfo?.Etablissement?.TypeCollectiviteDesignation}
          </div>
        }
        <div className="form-group">
          Désignation : {userInfo?.Etablissement?.Designation}
        </div>
      </div>
    );
  }

  const logo = () => {
    const timestamp = new Date().getTime(); 

    return (
      <div>
        {isLogoDispo ?
          <div css={{display: "flex", flexDirection: "row", justifyContent:"center", alignItems: "center"}}>
            <div css={{width: "120px", height: "120px"}}>
              <img 
                  src={logoUrl+`?t=${timestamp}`}
                  css={{ 
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain"
                  }}
              /> 
            </div>
          </div>
          :
          <div>
            Pas encore de logo téléchargé
          </div>
        }
      </div>
    );
  }

  const piecesJustif = () => {
    return (
      <div>
        <ul
          css={{
            padding: "0",
            fontFamily: "Helvetica",
          }}
        >
          {userPieceJustifList &&
            userPieceJustifList?.map((userPiece, index) => {
              return (
                <PieceJustifLine
                  key={index}
                  typePiece={typePiecesJustifList?.find(
                    (type) => type.Id === userPiece.TypePieceByProfilId
                  )}
                  pieceInfo={userPiece}
                />
              );
            })}
        </ul>
      </div>
    );
  }

  const adressePostaleEtablissement = () => {
    return (
      <div>
        <div
          css={{
            marginBottom: "20px",
          }}
        >
          <div>
            {(userInfo?.Etablissement?.LibelleVoieEtablissement && 
              userInfo?.Etablissement?.LibelleVoieEtablissement) || "Non renseignée"}
          </div>
          {userInfo?.Etablissement?.ComplementAdresseEtablissement && (
            <div>{userInfo?.Etablissement?.ComplementAdresseEtablissement}</div>
          )}
          <div
            css={{
              display: "inline-block",
            }}
          >
            {userInfo?.Etablissement?.CodePostalEtablissement}
          </div>
          <div
            css={{
              display: "inline-block",
              marginLeft: "30px",
            }}
          >
            {userInfo?.Etablissement?.CommuneDesignationEtablissement}
          </div>
        </div>
      </div>
    );
  }

  const adressePostaleRepresentant = () => {
    return (
      <div>
        <div
          css={{
            marginBottom: "20px",
          }}
        >
          <div>
            {(userInfo?.Etablissement?.LibelleVoieRepresentant &&
              userInfo?.Etablissement?.LibelleVoieRepresentant) || "Non renseignée"}
          </div>
          {userInfo?.Etablissement?.ComplementAdresseRepresentant && (
            <div>{userInfo?.Etablissement?.ComplementAdresseRepresentant}</div>
          )}
          <div
            css={{
              display: "inline-block",
            }}
          >
            {userInfo?.Etablissement?.CodePostalRepresentant}
          </div>
          <div
            css={{
              display: "inline-block",
              marginLeft: "30px",
            }}
          >
            {userInfo?.Etablissement?.CommuneDesignationRepresentant}
          </div>
        </div>
      </div>
    );
  }

  const beneficiaires = () => {
     return (
        <div css={{padding:"10px"}}>
          {
            beneficiairesList && beneficiairesList.length > 0 ?
              <Table celled padded>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell > </Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Nom</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Prenom</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Date de Naissance</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {beneficiairesList?.map((beneficiaire, index) =>
                    <Table.Row>
                      <Table.Cell textAlign='center'>{index + 1}</Table.Cell>
                      <Table.Cell textAlign='center'>{beneficiaire.Nom}</Table.Cell>
                      <Table.Cell textAlign='center'>{beneficiaire.Prenom}</Table.Cell>
                      <Table.Cell textAlign='center'>{new Date(beneficiaire.DateNaissance!).toLocaleDateString()}</Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
              : <h4 css={{textAlign: "center"}}>Aucun bénéficiaire effectif</h4>
          }
        </div>
    )
  }

  const experienceInvestissement = () => {
    return (
      <div
          css={{
            display:"flex",
            flexDirection:"row",
            justifyContent:"space-between",
            alignItems:"center",
            marginTop:"15px",
            borderTop: "1px solid rgba(85,99,127,0.2)",
            padding: "10px"
          }}
        >
          {
            !userInfo?.Etablissement?.IsExperienceInvestissementDone || daysLeft(new Date(userInfo.Etablissement.DateExperienceInvestissement!)) < 0
            ?
            <>
                <p css={{width:"70%"}}>
                  Répondre aux questions nous permettra de mieux vous guider dans vos investissements.
                </p>
                <Icon
                    name="question circle"
                    className="last-input"
                    size="huge"
                    color="blue"
                    css={{float: "right"}}
                />
            </>
            :
            <>
              <p>
                A renouveler dans {daysLeft(new Date(userInfo?.Etablissement.DateExperienceInvestissement!))} Jours
              </p>
              <Icon
                  name="check circle"
                  className="last-input"
                  size="huge"
                  color="green"
              />
            </>
          }
      </div>
    );
  }

  const capaciteInvestissement = () => {
    return (
     <div
          css={{
            display:"flex",
            flexDirection:"row",
            justifyContent:"space-between",
            alignItems:"center",
            marginTop:"15px",
            borderTop: "1px solid rgba(85,99,127,0.2)",
            padding: "10px"
          }}
        >
          {
            userInfo?.Etablissement?.ValeurPatrimonialeNette 
            ?
              <>
                <p>
                  Votre capacité d'investisement est de : {userInfo?.Etablissement?.ValeurPatrimonialeNette  / 10} €.
                </p>
                <Icon
                    name="check circle"
                    className="last-input"
                    size="huge"
                    color="green"
                />
              </>
            :
              <>
                <p>
                  Simuler et nous transmettre votre capacité d'investissement nous permettra de mieux nous guider.
                </p>
                <Icon
                    name="question circle"
                    className="last-input"
                    size="huge"
                    color="blue"
                />
              </>
          }
      </div>
    );
  }

  const updateUserInfo = (userInfo: UserInfoResponse) => {
    setUserInfo(userInfo)
    const action: AuthenticationAction = { type: CHANGE_IDENTITY, user: userInfo }
    dispatch(action)
    localStorage.setItem("user_info", JSON.stringify(userInfo));
  }

  const onValidateCorrectionRefused = () => {
    setIsValidateModalOpen(false)
    setValidateErrorMessage(true)
  }


	return (
    <Container css={{
      marginTop: '30px'
    }}>
        <LoadingSpinner area="main">
          <ProfilCompletionHeader
            status={userInfo?.OnboardingStatus}
          />
          {validateErrorMessage && (
            <Segment css={{position:"relative", backgroundColor:"#f2dede !important", display:"flex", flexDirection:"row", alignItems:"center"}}>
              <div>
                <p 
                  css={{
                    color:"#a94442", 
                    fontSize:"16px", 
                    fontWeight:"bold"}}
                >
                  Vous avez une ou plusieurs pièces justificatives en erreur, veuillez en soumettre une nouvelle avant de valider vos modifications
                </p>
              </div>
            </Segment>
          )}
          {userInfo?.OnboardingStatus?.Code === OnboardingStatus.ERREUR && (
            <div css={{marginBottom:"20px"}}>
              <Modal
                open={isValidateModalOpen}
                trigger={
                  <SimpleButton 
                    label="Valider mes modifications" 
                    onClick={() => setIsValidateModalOpen(true)}
                  />}
              >
                <Header icon='edit outline' content='Terminer mes modifications' />
                <Modal.Content>
                  <p>
                    Voulez-vous vraiment soumettre vos corrections ? Elles seront définitives et vous n'aurez plus la possibilité de les modifier.
                  </p>
                  <Modal.Actions>
                    <SubmitButton 
                      label="Confirmer quand même"
                      validateForm={() => {
                        for(let i = 0; i < userPieceJustifList.length; i++) {
                          var element = userPieceJustifList[i]
                          if(element.StatutPieceCode !== StatutPieceJustificative.VALIDE && element.StatutPieceCode !== StatutPieceJustificative.TRANSMISE) {
                            return false
                          }
                        }

                        return true
                      }}
                      action={() => userService.validateModifications(loginProps?.oauth, loginProps?.oauth?.userId)}
                      onActionSuccess={() => setReload(true)}
                      onFormNotValid={onValidateCorrectionRefused}
                    />
                  </Modal.Actions>
                </Modal.Content>
              </Modal>  
            </div>
          )}
          <Grid stackable doubling>
            <Grid.Column width={8}> 
                <EditableBlock
                  title="Adresse mail"
                  editable={false}
                  editor={(close) => null}
                >
                  {adresseMail()}
                </EditableBlock>

              <div 
                css={{
                  border: !userInfo?.Etablissement?.IsVerificationDone ? "solid 2px #B80000 !important" : "", 
                  borderRadius: "5px !important"
                }}
              >   
                <EditableBlock
                  title="Identité du représentant"
                  editButtonColor={!userInfo?.Etablissement?.IsVerificationDone ? "#B80000" : "#005877"}
                  editable={!userInfo?.Etablissement?.IsVerificationDone}
                  editor={(close) => {
                    return (<SubscriptionIdentiteRepresentantForm
                      user={userInfo}
                      onSubmitSuccess={(
                        response: OnboardingCollectiviteResponse
                      ) => {
                        setUserInfo(response.UserInfo)
                        close();
                      }}
                    />)
                  }}
                >
                  {identiteRepresentant()}
                </EditableBlock>
              </div>

              <div css={{border:!userInfo?.Etablissement?.IsAdresseRepresentantDone ? "solid 2px #B80000 !important" : "", borderRadius: "5px !important", marginTop:"0.78rem"}}>
                <EditableBlock
                  title="Adresse représentant"
                  editButtonColor={!userInfo?.Etablissement?.IsAdresseRepresentantDone ? "#B80000" : "#005877"}
                  editable={!userInfo?.Etablissement?.IsAdresseRepresentantDone}
                  editor={(close) => (
                    <SubscriptionAdresseRepresentant
                      userInfo={userInfo}
                      onSubmitSuccess={(
                        response: OnboardingCollectiviteResponse
                      ) => {
                        setUserInfo(response.UserInfo)
                        close();
                      }}
                    />
                  )}
                >
                  {adressePostaleRepresentant()}
                </EditableBlock>
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
          
              {((loginProps?.user?.Etablissement?.CategorieCode === CategorieEtablissement.SOCIETE) || (loginProps?.user?.Etablissement?.TypeInvestisseurMoraleCode === TypeInvestisseur.SOCIETE)) ? 
                <EditableBlock
                  title="Identité de la société"
                  editable={false}
                  editor={(close) => null}
                >
                  {identiteSEM()}
                </EditableBlock>
                :
                <EditableBlock
                 title="Etablissement"
                 editable={false}
                 editor={(close) => null}
               >
                 {etablissement()}
               </EditableBlock>
              }

              {loginProps.user?.ProfilCode === ProfilCode.PORTEUR_PROJET &&
                <EditableBlock
                  title="Logo"
                  editButtonColor="#005877"
                  editor={(close) => 
                    <LogoUploadForm
                      onUploadSuccess = {() => {
                        setIsLogoDispo(true)
                        setLogoUrl(`${process.env.REACT_APP_BASE_URL}/public/get-logo/${loginProps?.oauth?.etablissementId!}`)
                      }}
                      closeModal={close}
                    />
                  }
                >
                  {logo()}
                </EditableBlock>
              }
              <div css={{border: !userInfo?.Etablissement?.IsAdresseEtablissementDone ? "solid 2px #B80000 !important" : "", borderRadius: "5px !important", marginTop:"1rem"}}>
                <EditableBlock
                  title="Adresse de l'établissement"
                  editButtonColor={!userInfo?.Etablissement?.IsAdresseEtablissementDone ? "#B80000" : "#005877"}
                  editable={!userInfo?.Etablissement?.IsAdresseEtablissementDone}
                  editor={(close) => (
                    <SubscriptionAdresseEtablissement
                      userInfo={userInfo}
                      onSubmitSuccess={(
                        response: OnboardingCollectiviteResponse
                      ) => {
                        setUserInfo(response.UserInfo)
                        close();
                      }}
                    />
                  )}
                >
                  {adressePostaleEtablissement()}
                </EditableBlock>
              </div>
              {/* <div>
                <EditableBlock
                  title="Pièces justificatives"
                  editButtonColor={!userInfo?.Etablissement?.IsPieceJustifDone ? "#B80000" : "#005877"}
                  editable={!userInfo?.Etablissement?.IsPieceJustifDone}
                  editor={(close) => (
                    <OnboardingInvestisseurMoralPorteurProjetDocumentListForm
                      onActionSuccess={(
                            response: MultipleFileRegistrationResponse
                          ) => {
                            setUserInfo(response.UserInfo)
                            setValidateErrorMessage(false)
                            setUserPieceJustifList(response.EtablissementPieceList!)
                            close();
                          }}
                      />
                  )}
                >
                  {piecesJustif()}
                </EditableBlock>
              </div> */}
            </Grid.Column>

            {/* {((loginProps?.user?.Etablissement && loginProps?.user?.Etablissement.CategorieCode === CategorieEtablissement.SOCIETE) || 
            (loginProps?.user?.Etablissement?.TypeInvestisseurMoraleCode === TypeInvestisseur.SOCIETE)) && 
              <Grid.Column width={16}>
                <div>
                  <EditableBlock
                      title="Bénéficiaires effectifs"
                      editButtonColor={!userInfo?.IsBeneficiairesEffectifsDone ? "#B80000" : "#005877"}
                      editable={!userInfo?.IsBeneficiairesEffectifsDone}
                      editor={(close) => (
                        <SubscriptionSEMFormBeneficiaires
                          beneficiaires={beneficiairesList} 
                          isOnboarding = {false}
                          onSubmitSuccess={(response: OnboardingSEMResponse)=>{
                            setUserInfo(response.UserInfo)
                            setBeneficiairesList(response.UserInfo?.BeneficiairesList ?? [])
                            close();
                          }}     
                          onCancel={close}                 
                        />
                      )}
                    >
                      {beneficiaires()}
                  </EditableBlock>
                </div>
              </Grid.Column>
            } */}
          </Grid>
        </LoadingSpinner>
    </Container>
  );
}

